import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import SignUpForm from "../SignUpForm";
import Contact from "../Contact";

const PartnersWithMatchi = () => {
  return (
    <section id="news-article" className="section bg-lig text-black">
      <Container>
        <Row className="justify-content-md-center">
          <Col md={12} className="text-center">
            <img
              src="../../images/SportAi_x_Matchi_cover_image.png"
              alt="news-image"
              style={{ maxHeight: "50vh" }}
              className="img-fluid"
            />
          </Col>
        </Row>
        <Row className="justify-content-md-center">
          <Col lg={6} md={8}>
            <header className="article-header mt-5">
              <h1 className="article-title">
              SportAI and MATCHi partner to bring AI-powered sports technique analysis to 1M+ players worldwide
              </h1>
              <div className="article-meta mt-3">
                <time className="small text-muted" dateTime="2024-01-XX">
                  Oslo, Norway & Gothernburg, Sweden - 5th September 2024
                </time>
              </div>
            </header>
            <section className="article-content mt-3">
              <h4 className="article-subtitle" style={{ lineHeight: 1.5 }}>
              Signifying SportAI’s first major commercial contract, this MATCHi collaboration will grant clubs, coaches and players across 30 countries access to elite-level sport technique analysis once reserved for professionals
              </h4>
              <h5 className="mt-5 fw-600">Oslo, Norway & Gothernburg, Sweden - 5th September 2024:</h5>
              <p>
              <a href="https://www.sportai.com/">SportAI</a>, the B2B sports technology company using AI to enhance sports technique coaching, commentary and analysis, is today announcing its partnership with <a href="https://www.matchi.se">MATCHi</a>, the fastest growing platform for padel and racket sports globally. This collaboration will see SportAI offering a suite of AI-powered experiences for racket sports players within the MATCHi network. The network includes 1M+ users, 2.6k venues, and 14k courts across 30 countries. SportAI, co-founded by ex-NCAA Division 1 tennis player <a href="https://www.linkedin.com/in/laurenmartinpedersen/">Lauren Pedersen</a> (CEO) in late 2023, also announced its <a href="https://thenextweb.com/news/sportai-analytics-for-tennis-golf">$1.8M seed funding round this month</a>. MATCHi is SportAI’s first major commercial contract.
              </p>
              <p>
                The Paris Olympics ignited conversation about AI’s role in sports, a burgeoning sector set to reach <a href="https://www.alliedmarketresearch.com/artificial-intelligence-in-sports-market-A12905">$29.7B by 2032</a>, with IBM and the United States Tennis Association (USTA) recently announcing AI-powered fan features for the upcoming US Tennis Open.
              </p>
            </section>
            <section className="article-content mt-3">
              <h5 className="mt-5 fw-600">
              Enhancing the Racket Sports Experience with AI
              </h5>
            <p>
            Players and coaches within MATCHi’s network will now be able to utilise SportAI’s proprietary technology to enhance their sporting technique and training. SportAI’s offering includes advanced technique analysis, detailed match statistics, and highlight videos. By integrating SportAI’s technology, MATCHi will unlock the full potential of its expanding TV camera and streaming infrastructure (2k of its courts are now camera-enabled), enhancing the playing and viewing experience of its 1M+ players, coaches and clubs.
            </p>
            </section>
            <section className="article-content mt-3">
              <h5 className="mt-5 fw-600">
              SportAI: Democratising Access to Elite Training
              </h5>
            <p>
            SportAI specialises in developing advanced sports technology by utilising computer vision, machine learning and biometric technology for objective technique analysis and AI-driven data insights. The company aims to democratise access to top coaching expertise, levelling the playing field for athletes worldwide. SportAI analyzes players’ technique in real-time, such as their tennis or padel swing, before delivering instant, personalized feedback for improving performance (demo here). An individual’s technique can be instantly compared to thousands of other players, including top sporting professionals, with objective and precise written and visual analysis. Broadcasters can also use the technology to enrich their live sporting commentary, while equipment brands can use it for product recommendations.
            </p>
            <img
              src="../../images/MATCHi_TV_x_SportAI.png"
              alt="news-image"
              style={{ maxHeight: "50vh" }}
              className="img-fluid"
            />
            </section>
            <section className="article-content mt-3">
              <h5 className="mt-5 fw-600">
              MATCHi: The World’s Fastest Growing Racket Sports Platform 
              </h5>
            <p>
            MATCHi has been at the forefront of racket sports services since its inception in Sweden in 2012. Now managing a global community of racket sports clubs, coaches and players, MATCHi offers a user-friendly system for paying for and booking courts, managing activities and courses, player partnering, league tracking and automated racket rental.
            </p>
            </section>
            <section className="article-content mt-3">
              <p>
              <b><a href="https://www.linkedin.com/in/laurenmartinpedersen/">Lauren Pedersen</a>, CEO and co-founder of SportAI,</b> comments: <em>"We are incredibly proud to partner with MATCHi to offer AI-powered experiences to their million-plus racket sports players worldwide. This partnership signifies a significant step towards democratising sports technique analysis and providing unparalleled insights and experiences to players, coaches and clubs around the world."</em>
              </p>
              <p>
              <b><a href="https://www.linkedin.com/in/danielekman/">Daniel Ekman</a>, MATCHi CEO and founder,</b> adds, <em>"At MATCHi, we are committed to embracing the power of AI to transform the racket sports experience for our clients. This partnership with SportAI is a testament to our progressive approach. We are excited to offer our clubs and players enhanced capabilities through AI-powered technology, paving the way for a more immersive and improved sports experience."</em>
              </p>
            </section>

            <section className="article-content mt-3">
              <h6 className="mt-5 fw-600">
              About SportAI
              </h6>
            <p>
            SportAI is a Norwegian-founded B2B sports technology company dedicated to enhancing sports technique coaching, commentary and analysis using AI. With expertise in computer vision and machine learning, SportAI brings objectivity to sports technique analysis, offering instant data-driven insights to training facilities, teams, broadcasters, retailers and equipment brands. The company was founded in late 2023 by Norwegian tech and software industry experts <a href="https://www.linkedin.com/in/laurenmartinpedersen/">Lauren Pedersen</a> (CEO), <a href="https://www.linkedin.com/in/flonge/">Felipe Longé</a> (CTO), <a href="https://www.linkedin.com/in/andreas-thome-0567b95/">Andreas Thome</a> (Board Chairman), <a href="https://www.linkedin.com/in/espen-agdestein-5118512/">Espen Agdestein</a> (Board member), and <a href="https://www.linkedin.com/in/trond-kittelsen-2725a03/">Trond Kittelsen</a> (Head of Commercial). SportAI employs a team of seven people and has raised $2,6M to date.
            </p>
            </section>
            <section className="article-content mt-3">
              <h6 className="mt-5 fw-600">
              About MATCHi
              </h6>
            <p>
            <a href="https://www.matchi.se">MATCHi</a> is a complete booking system for racket sports venues worldwide. Founded in 2012 by <a href="https://www.linkedin.com/in/danielekman/">Daniel Ekman</a> (CEO), the business has built a blend between user-friendly technology and powerful functionality. Its origins began in Sweden and the company now has 1M+ users, operating in 30 countries with over 2.6k active venues and managing over 14k courts. 
            </p>
            </section>

            








              <br />
            <p>
              For more information please contact:
              <br />
              Lauren Pedersen
              <br />
              Chief Executive Officer | SportAI
              <br />
              <a className="text-black" href="lauren.pedersen@sportai.no">
                lauren.pedersen@sportai.com
              </a>
              <br />
              <a className="text-black" href="tel:004790663094">
                +47 906 63 094
              </a>
              <br />
              or{" "}
              <a className="text-black" href="contact@sportai.no">
                contact us
              </a>
            </p>
            <p className="small text-muted">
              1. Source: https://www.sportsbusinessjournal.com/Articles/2024/09/05/sportai-matchi
            </p>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default PartnersWithMatchi;
