import React from "react";

const StandardMenuDefaultIntro = () => {
  return (
    <section
      id="home"
      className="bg-primary d-flex fullscreen-with-header position-relative"
    >
    </section>
  );
};

export default StandardMenuDefaultIntro;
