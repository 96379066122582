import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import SignUpForm from "../SignUpForm";
import Contact from "../Contact";

const NorwegianTechLeaders = () => {
    return (
        <section id="news-article" className="section bg-lig text-black">
            <Container>
                <Row className="justify-content-md-center">
                    <Col md={12} className="text-center">
                        <img
                            src="../../images/empowering-the-game.jpg"
                            alt="blog-image"
                            style={{ maxHeight: "50vh" }}
                            className="img-fluid"
                        />
                        <p className="small text-muted">
                            Photo: Lauren Pedersen.
                        </p>
                    </Col>
                </Row>
                <Row className="justify-content-md-center">
                    <Col lg={6} md={8}>
                        <header className="article-header mt-5">
                            <h1 className="article-title">
                                Empowering the Game: Inside SportAI with CEO Lauren Pedersen
                            </h1>
                            <div className="article-meta mt-3">
                                <time className="small text-muted" dateTime="2024-01-XX">
                                    March 8, 2024
                                </time>
                            </div>
                        </header>
                        <section className="article-content mt-3">
                            <h4 className="article-subtitle" style={{ lineHeight: 1.5 }}>
                                Lauren Pedersen stands at the forefront of SportAI, a pioneering startup revolutionizing sports training with artificial intelligence. Her journey from competitive tennis player to tech innovator is a testament to her passion and dedication to enhancing athletic performance. This interview delves into the origins of SportAI, its mission to democratize sports technique analysis, and the exciting prospects on the horizon.
                            </h4>
                            <h5 className="mt-5 fw-600"></h5>
                            <p className="fw-600">
                                Q: Congratulations on the successful launch of your company! How are things progressing?</p>
                            <p>
                                LP: Thank you! Things have been going incredibly well. We've received a very positive response to our launch, which has been fantastic. The story behind our company seems to be resonating strongly with people, businesses, and the connections we've made since our announcement. It's an exciting opportunity, and we're thrilled with the progress so far.
                            </p>
                            <p className="fw-600">
                                Q: Could you share how the concept of SportAI came about?</p>
                            <p>
                                LP: The idea for SportAI was brewing for almost a year before we launched. It was quite organic - a mix of people came together with different strengths from the tech, sporting and business worlds, all motivated to build a great new sportstech company.  We saw the opportunity to make sports technique analysis available to the masses by working together with other businesses, enabling them to bring the power of AI to players around the world. We are such a complementary team, so it really was different people pitching in with different ideas until the lightbulb lit up and SportAI was born.
                            </p>
                            <p className="fw-600">
                                Q: What do you see as the main opportunities for SportAI?
                            </p>
                            <p>
                                LP: We see three main opportunities with SportAI. Firstly, offering technique analysis to players through coaches or training facilities can play a crucial role in helping athletes enjoy sports more fully. It really will democratize what is typically only accessible to elite level players with lots of funding. We will start with tennis, padel and golf but plan to expand to a wide range of sports over time. SportAI aims to support coaches and training facilities with technology-driven insights and scalability, enabling them to reach more students and grow their businesses sustainably.
                            </p>
                            <p>
                                Secondly, there's a significant gap in data accessibility for equipment manufacturers and retailers. They don’t have data on players so they have a hard time recommending the right equipment to match their needs. At the same time, it is very challenging for players to choose the right equipment with so many weights, sizes, and tensions available - it really is a confusing space. Many people delay purchasing or make the wrong choice, because they really don’t know what is right for them. SportAI seeks to bridge this gap, providing manufacturers with valuable data insights so they can make smart recommendations, innovate during their product development process and increase conversion rates.
                            </p>
                            <p>Lastly, broadcasters have successfully integrated data, stats and ball tracking into their coverage, but technique analysis is still very subjective, based on the commentator’s own view and experience. SportAI aims to engage fans by integrating technique analysis into broadcast, so fans can learn how technique is impacting performance.
                            </p>
                            <p>With AI at the helm, we believe SportAI can facilitate a journey of continuous improvement for athletes of all levels, from casual enthusiasts to promising prospects. It's an opportunity that has everyone at SportAI buzzing with excitement.</p>
                            <p className="fw-600">
                                Q: What's next for SportAI?
                            </p>
                            <p>LP: Currently, we're in the midst of product development, with beta customers coming on board to help refine our solution for commercial release. We also have plans to attend several events to raise awareness in key markets.
                            </p>
                            <p>The feedback we've received since our launch has been incredibly motivating, and it's driving us forward with even greater energy. From our board members to investors like Magnus Carlsen, everyone is fully onboard and eager for the journey ahead. Right now, we feel like we're leading 30-love in the first game of our first set—let's keep the momentum going!</p>
                            
                        </section>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default NorwegianTechLeaders;