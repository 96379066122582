import React from "react";

const BottomHeaderImgBgIntro = () => {
  return (
    <section id="home"> 
    </section>
  );
};

export default BottomHeaderImgBgIntro;
