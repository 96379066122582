import React, { useState } from "react";

const BottomHeader = () => {
  const [isNavModalClose, setIsNavModalClose] = useState(true);

  return (
    <header id="header" className="sticky-top">
    </header>
  );
};

export default BottomHeader;
