import React from "react";

const BottomHeaderVideoBgIntro = () => {
  return (
    <section id="home">
      
    </section>
  );
};

export default BottomHeaderVideoBgIntro;
