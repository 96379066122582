import React from "react";
const AboutUs = () => {
  return (
    // <section id="what-we-do" className="section">
    <section id="about" className="section">
      <div className="container">
        {/* Heading */}
        <h2 className="text-10 fw-600 text-center mb-5 wow fadeInUp">
          About Us
        </h2>
        {/* Heading end*/}
        <div className="row">
          <div className="col-lg-7 text-center text-lg-start wow fadeInUp mb-5">
          <div className="d-flex justify-content-center">
            <video src="https://sportai-public-website.s3.eu-north-1.amazonaws.com/company-intro.mp4" controls className="w-100 sai-rounded" />
          </div>          </div>
          <div className="col-md-1"></div>
          <div className="col-lg-4 text-center text-lg-start wow fadeInUp">
            <p className="text-5">
            We are SportAI, a pioneering Norwegian B2B sports technology company at the forefront of enhancing sports technique coaching, commentary, and analysis. Driven by our expertise in computer vision and machine learning, our mission is to bring objectivity to sports technique analysis. We're committed to elevating the performance of players, coaches, sports facilities, broadcasters, and equipment brands through data-driven insights.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
