import React from "react";
import { Link } from "react-scroll";
import { Parallax } from "react-parallax";
import { scrollDuration } from "../../../config/commonConfig";

const StandardMenuImgBgIntro = () => {
  return (
    <section id="home">
      <div className="hero-wrap">
        <div className="hero-mask opacity-3 bg-dark" />
        {/* <div
          className="hero-bg parallax"
          style={{ backgroundImage: 'url("images/intro-bg.jpg")' }}
        /> */}
        <Parallax
          bgImage="images/intro-bg.jpg"
          bgImageAlt="Intro"
        >
          <div className="hero-content d-flex fullscreen-with-header py-5">
            <div className="container my-auto py-4">
              <div className="row">
                <div className="col-lg-12 text-center order-1 order-lg-0">
                  <div className="text-wrapper">
                    <h1 className="text-9 text-white fw-600 mb-0 text-uppercase">
                      AI-Powered Sports Technique Analysis
                    </h1>
                    <p className="text-5 text-light">
                    </p>
                  </div>
                  <div className="button-wrapper">
                    <Link
                      className="btn btn-primary fw-00 rounded-0 smooth-scroll mt-5"
                      smooth="easeInOutQuint"
                      duration={scrollDuration}
                      style={{ cursor: "pointer" }}
                      to="about"
                    >
                      Learn More
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <Link
              className="scroll-down-arrow text-light smooth-scroll"
              smooth="easeInOutQuint"
              duration={scrollDuration}
              style={{ cursor: "pointer" }}
              to="about"
            >
              <span className="animated">
                <i className="fas fa-arrow-down" />
              </span>
            </Link>{" "}
          </div>
        </Parallax>
      </div>
    </section>
  );
};

export default StandardMenuImgBgIntro;
