import React from "react";
import { Link } from "react-router-dom";

const newsItems = [
  {
    title: "Norwegian tech leaders unite to launch AI-powered sports tech company",
    date: "January 23, 2024",
    image: "images/team.JPG",
    link: "/news/norwegian-tech-leaders-unite",
  },
  {
    title: "SportAI Raises $1.8M Seed Round Led by Skyfall Ventures",
    date: "August 19, 2024",
    image: "images/SportAI_Co_Founders.jpg",
    link: "/news/sport-ai-raises-capital",
  },
  {
    title: "SportAI Partners With Matchi",
    date: "September 5, 2024",
    image: "images/SportAi_x_Matchi_cover_image.png",
    link: "/news/sport-ai-partners-with-matchi",
  },
  // Add more news items here as needed
];

const News = () => {
  return (
    <section id="news" className="section bg-dark-green text-white">
      <div className="container">
        <h2 className="text-10 fw-600 text-center mb-5 wow fadeInUp text-white">
          News
        </h2>
        <div className="row justify-content-center">
          {newsItems.map((news, index) => (
            <div
              className={`col-md-${newsItems.length === 1 ? '12' : '6'} d-flex flex-column align-items-center`}
              key={index}
              style={newsItems.length === 1 ? { maxWidth: '600px' } : {}}
            >
              <div className="news-content text-center sai-hover-expand" style={{ margin: 16 }}>
                <Link to={news.link}>
                  <div className="sai-article">
                    <img
                      src={news.image}
                      alt="news-image"
                      className="img-fluid sai-rounded"
                    />
                  </div>
                  <h3 className="mt-3 fw-200 text-white">{news.title}</h3>
                </Link>
                <p className="mt-2 fw-300">{news.date}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default News;
