import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const DataProcessingAgreement = () => {
  return (
    <section id="dpa" className="section bg-lig text-black">
      <Container>
        <Row className="justify-content-md-center">
          <Col lg={8} md={10}>
            <header className="dpa-header mt-5">
              <h1 className="dpa-title">Data Processing Agreement</h1>
              <p className="mt-4">Last updated: August 2, 2024</p>
              <nav className="dpa-toc mt-4">
                <ul>
                  <li>1. Purpose</li>
                  <li>2. Definitions</li>
                  <li>3. Contractual documents</li>
                  <li>4. Duration of the assignment/notice of termination</li>
                  <li>5. Relationship of the Parties</li>
                  <li>6. Controller to Controller Clauses</li>
                  <li>7. Controller to Processor Clauses</li>
                  <li>8. Technical and organisational measures</li>
                  <li>9. Liability</li>
                  <li>10. Final Provisions</li>
                  <li>Appendix 1: Personal data</li>
                  <li>Appendix 2: Technical and Organisational Measures</li>
                  <li>Appendix 3: Contact details of the parties</li>
                  <li>Appendix 4: Subprocessors</li>
                </ul>
              </nav>
            </header>

            <section className="dpa-content mt-5">
              <h2>1. Purpose</h2>
              <p>
                This Data Processing Addendum forms a part of the service
                agreement (the “Agreement”) between Sport AI and the Client.
                Sport AI and the Client have entered into the Agreement for the
                provision of the Sport AI services. This Data Processing
                Agreement (hereinafter “DPA”) and its applicable DPA Appendixes
                apply to the Processing of Personal Data by Parties subject to
                the Data Protection Laws in order to provide services
                (“Services”) pursuant to the Agreement between Sport AI and the
                Client. To provide the Services in accordance with the
                Agreement, Sport AI processes Personal Data as described in
                Appendix 1. As part of their contractual relations, the Parties
                shall undertake to comply with the applicable Data Protection
                Laws with respect to the processing of Personal Data covered
                under this DPA.
              </p>

              <h2>2. Definitions</h2>
              <p>
                “Sport AI Services” means the i) access to the Sport AI Console
                in order to connect to Sport AI APIs and use our services, which
                ii) allows the Client to provide URLs pointing to videos to be
                processed by Sport AI, and provide editing and technical
                analysis of the videos content.
                <br />
                "Data Protection Laws" means all data protection laws and
                regulations applicable to a party's processing of Client’s
                Personal Data under the Agreement, including, where applicable,
                EU/UK Data Protection Laws, Non-EU Data Protection Laws, and any
                other applicable data protection laws.
                <br />
                Capitalized terms used in this Addendum shall have the same
                meaning given to them under the EU GDPR, unless a different
                meaning is given within the DPA.
              </p>

              <h2>3. Contractual documents</h2>
              <p>
                This Addendum and its Appendixes constitute the entire Data
                Processing Agreement between the Parties for the provision of
                the services pursuant to the Agreement. It replaces all previous
                agreements relating to its object. Some of the contractual
                documents may be amended or enriched during the fulfilment of
                the DPA. In any event, these amendments or enrichments must be
                covered by an amendment signed by the Parties. No modifications
                may be made to the DPA and its Appendixes without a document
                signed by both Parties.
              </p>

              <h2>4. Duration of the assignment/notice of termination</h2>
              <p>
                The duration of the assignment (term of the DPA) is coextensive
                with the term of the Agreement. The termination of this DPA
                therefore depends on the provisions concerning the duration and
                the termination of the Agreement. Termination of the Agreement
                shall also have the effect of terminating this DPA. Furthermore,
                the premature termination of this DPA upon written notice to the
                other Party shall be permissible in the event of such other
                Party’s serious breach of statutory or contractual data
                protection provisions under the Data Protection Laws, insofar as
                the contracting Party in question cannot reasonably be expected
                to continue this DPA. The parties acknowledge that the
                termination of the DPA at any time and for any reason does not
                exempt them from their obligations under the Data Protection
                Laws relating to the collection, processing and use of Personal
                Data.
              </p>

              <h2>5. Relationship of the Parties</h2>
              <p>
                Sport AI will process the Personal Data as Controller for the
                purposes to the extent relevant to the Services in order to (a)
                manage the relationship with Client; (b) administer invoices to
                the client; (c) administer access to the Sport Ai Services, (d)
                comply with legal or regulatory obligations; and (e) as
                otherwise permitted under Data Protection Laws and in accordance
                with this DPA and the Agreement. The parties acknowledge and
                agree that each is acting independently as a Controller with
                respect to Personal Data and the parties are not joint
                controllers. Sport AI will process the Personal Data in
                accordance as Controller as set forth in clause 6. Where Sport
                AI processes the Personal Data in the performance of the
                Services Sport AI acts as the Client’s Processor only where the
                Client determines the purpose and means of the processing. In
                such circumstances, Sport AI will process the Personal Data in
                accordance with the Client’s instructions, as Controller, to
                Sport AI, as Processor, as set forth in clause 7.
              </p>

              <h2>6. Controller to Controller Clauses</h2>
              <p>
                In respect of the Personal Data processed by the Parties acting
                as a Controller under this Addendum:
              </p>
              <ul>
                <li>
                  Each Party will ensure that the persons engaged in the
                  processing of Personal Data are bound by appropriate
                  confidentiality obligations;
                </li>
                <li>
                  comply promptly with any lawful request from the other Party
                  requesting access to, copies of, or the amendment, transfer or
                  deletion of the Personal Data to the extent the same is
                  necessary to allow either Party to fulfil its own obligations
                  under the Data Protection Laws;
                </li>
                <li>
                  notify the other Party within forty-eight (48) hours if it
                  receives any complaint, notice or communication (whether from
                  a data subject, competent supervisory authority or otherwise)
                  relating to the processing of Personal Data or to either
                  Party’s compliance with Data Protection Laws under this
                  Addendum, and provide the other Party with reasonable
                  cooperation, information and assistance in relation to any
                  such complaint, notice or communication;
                </li>
                <li>
                  notify the other Party immediately if it becomes aware of a
                  breach of this clause, in which case the Party in breach shall
                  take any and all steps to remedy such breach;
                </li>
                <li>
                  facilitate the handling of any Personal Data Breach, that is
                  likely to result in a risk to the rights and freedoms of
                  natural persons for which the other Party is responsible as
                  soon as reasonably practicable upon becoming aware, which
                  shall include the Party responsible for the breach notifying
                  the relevant supervisory authority, promptly and in any event
                  no later than seventy-two (72) hours after becoming aware of
                  it, as well as the relevant data subjects without undue delay,
                  where required by the Data Protection Laws;
                </li>
                <li>
                  provide reasonable assistance in assisting the other Party’s
                  obligations under the Data Protection Laws. Both parties may
                  engage with third parties in connection with the Services and
                  agree to comply with the applicable requirements under Data
                  Protection Laws in relation to third parties. Both parties
                  shall be liable for the acts and omissions of their respective
                  third parties to the same extent such parties would be liable
                  under the terms of this DPA, except as otherwise set forth in
                  the Agreement.
                </li>
              </ul>

              <h2>7. Controller to Processor Clauses</h2>
              <p>
                In respect of the Personal Data processed by Sport AI as a
                Processor acting on behalf of the Client under this Addendum,
                the Processor will:
              </p>
              <ul>
                <li>
                  process the Personal Data only on the Client’s written
                  instructions, unless required by law to process it differently
                  (in which case it shall, if permitted by such law, promptly
                  notify the Client of that requirement before processing);
                </li>
                <li>
                  process the Personal Data only to the extent, and in such a
                  manner, as is necessary for the purposes of carrying out its
                  obligations under the Agreement;
                </li>
                <li>
                  ensure that persons engaged in the processing of Personal Data
                  are bound by appropriate confidentiality obligations;
                </li>
                <li>
                  keep a record of the processing it carries out, and ensure the
                  same is accurate;
                </li>
                <li>
                  comply promptly with any lawful request from Client requesting
                  access to, copies of, or the amendment, transfer or deletion
                  of the Personal Data to the extent the same is necessary to
                  allow Client to fulfil its own obligations under the Data
                  Protection Laws, including Client’s obligations arising in
                  respect of a request from a data subject;
                </li>
                <li>
                  notify Client promptly if it receives any complaint, notice or
                  communication (whether from a data subject, competent
                  supervisory authority or otherwise) relating to the
                  processing, the Personal Data or to either party’s compliance
                  with the Data Protection Laws as it relates to this Addendum,
                  and provide Client with reasonable co-operation, information
                  and other assistance in relation to any such complaint, notice
                  or communication;
                </li>
                <li>
                  ensure in each case that, prior to the processing of any
                  Personal Data by any Sub-Processor, the Processor and the
                  Sub-Processor agree to contract on the terms set out in this
                  Data Protection Addendum (“Relevant Terms”). The Processor
                  shall procure the performance of the Relevant Terms by the
                  Sub-Processor and shall be directly liable to Client for any
                  breach by the Sub-Processor of any of the Relevant Terms;
                </li>
                <li>
                  only transfer the Personal Data outside of the European
                  Economic Area if it has fulfilled each of the following
                  conditions: (i) it has in place any of the specifically
                  approved safeguards for data transfers (as recognized under
                  the Data Protection Laws) in relation to the transfer; (ii)
                  data subjects continue to have enforceable rights and
                  effective legal remedies following the transfer; (iii) it
                  provides an adequate level of protection to any Personal Data
                  that is transferred (including by way of a European Commission
                  finding of adequacy); and (iv) it complies with reasonable
                  instructions with respect to the transfer;
                </li>
                <li>
                  inform Client without undue delay within forty-eight (48)
                  hours after having become aware of a breach if any Personal
                  Data processed under this Addendum is lost or destroyed or
                  becomes damaged, corrupted, or unusable or is otherwise
                  subject to unauthorised or unlawful processing including
                  unauthorised or unlawful access or disclosure (“Personal Data
                  Breach”);
                </li>
                <li>
                  promptly provide Client with full cooperation and assistance
                  in respect of the Personal Data Breach and all information in
                  the Processor's possession concerning the Personal Data
                  Breach, including the following: the possible cause and
                  consequences of the Personal Data Breach; the categories of
                  Personal Data and the approximate number of data subjects
                  involved; and the measures taken by the Processor to mitigate
                  any damage;
                </li>
                <li>
                  inform Client promptly if it receives a request from a data
                  subject exercising their data subject rights and provide
                  Client with reasonable cooperation and assistance in relation
                  to such request;
                </li>
                <li>
                  not disclose the Personal Data to any third party other than
                  at the request of the Client or as otherwise required under
                  the Agreement;
                </li>
                <li>
                  provide reasonable assistance to the Client in complying with
                  its obligations under Data Protection Laws with respect to
                  security, breach notifications, data protection impact
                  assessments, and consultations with supervisory authorities or
                  regulators;
                </li>
                <li>
                  provide Client with all information that is necessary to
                  enable Client to monitor the Processor's compliance with the
                  Data Protection Laws and its obligations under this Addendum
                  at any time during regular business hours. Sport AI may
                  satisfy Client’s right of audit under the Data Protection Laws
                  in relation to Personal Data, by providing an audit report not
                  older than eighteen (18) months, prepared by an independent
                  external auditor demonstrating that Sport AI’s technical and
                  organisational measures are sufficient and in accordance with
                  an accepted industry audit standard. Sport AI reserves the
                  right to refuse audit requests from an entity that is a
                  competitor of Sport AI.; and
                </li>
                <li>
                  delete or return that Personal Data to Client at the end of
                  the duration of the processing, and at that time delete or
                  destroy existing copies. If return or destruction is
                  impracticable or prohibited by law, rule or regulation, Sport
                  AI shall take measures to block such Personal Data from any
                  further processing (except to the extent necessary for
                  processing required by law, rule or regulation) and shall
                  continue to appropriately protect the Personal Data remaining
                  in its possession, custody, or control. Client acknowledges
                  and agrees that Sport AI may (i) engage its Affiliates and
                  Sub-Processors listed in Appendix 4 to this Addendum to access
                  and process Personal Data in connection with the Services and
                  (ii) from time to time engage additional third parties for the
                  purpose of providing the Services, including without
                  limitation the processing of Personal Data. By way of this
                  Addendum, Client provides general written authorization to
                  Sport AI to engage Sub-Processors as necessary to perform the
                  Services.
                </li>
                <li>
                  A list of Sport AI’s current Sub-Processors (the “List”) will
                  be made available to Client, through a link provided by Sport
                  AI, via email, or through other means made available to the
                  Client. Such a List may be updated by Sport AI from time to
                  time. Sport AI provides a mechanism to subscribe to
                  notifications of new Sub-Processors and Client agrees to
                  subscribe to such notifications where available. At least ten
                  (10) days before enabling any third party other than existing
                  Sub-Processors to access or participate in the processing of
                  Personal Data, Sport AI will add such third parties to the
                  List and notify Client. The Client may object to such an
                  engagement by informing Sport AI within ten (10) days of
                  receipt of the aforementioned notice by Sport AI, provided
                  such objection is in writing and based on reasonable grounds
                  relating to data protection. Client acknowledges that certain
                  Sub-Processors are essential to providing the Services and
                  that objecting to the use of a Sub-Processor may prevent Sport
                  AI from offering the Services to Client.
                </li>
                <li>
                  If Client reasonably objects to an engagement in accordance
                  with Section 7, and Sport AI cannot provide a commercially
                  reasonable alternative within a reasonable period of time,
                  Client may discontinue the use of the affected Service by
                  providing written notice to Sport AI. Discontinuation shall
                  not relieve Client of any fees owed to Sport AI under the
                  Agreement.
                </li>
                <li>
                  If Client does not object to the engagement of a third party
                  in accordance with Section 7 within ten (10) days of notice by
                  Sport AI, that third party will be deemed a Client approved
                  Sub-Processor for the purposes of this Addendum. Sport AI will
                  enter into a written agreement with the Sub-Processor imposing
                  on the Sub-Processor data protection obligations comparable to
                  those imposed on Sport AI under this Addendum with respect to
                  the protection of Personal Data. In case a Sub-Processor fails
                  to fulfill its data protection obligations under such written
                  agreement with Sport AI, Sport AI will remain liable to Client
                  for the performance of the Sub-Processor’s obligations under
                  such agreement.
                </li>
              </ul>

              <h2>8. Technical and organisational measures</h2>
              <p>
                Sport AI shall take suitable technical and organisational
                measures appropriate to the risk to ensure for protection of the
                security, confidentiality and integrity of Personal Data it
                Processes under this DPA. Sport AI guarantees that it has
                carried out the technical and organisational measures specified
                in Appendix 2 to this DPA. The technical and organisational
                measures are subject to the current state of technology and
                technical progress. In this regard, Sport AI is permitted to
                implement adequate alternative measures, provided that these
                measures may not provide a lower level of security to the Client
                data than the stipulated measures in Appendix 2 to this DPA.
              </p>

              <h2>9. Liability</h2>
              <p>
                This DPA is without prejudice to the rights and obligations of
                the Parties under the Agreement which shall continue to have
                full force and effect, including any limitations and exclusions
                on liability contained therein which shall apply to this DPA as
                if fully set forth herein.
              </p>

              <h2>10. Final Provisions</h2>
              <p>
                If individual provisions of this DPA should be or become
                ineffective, this shall not affect its remaining provisions. The
                Parties undertake to replace the ineffective provisions with a
                legally valid provision that comes closest to the purpose of the
                ineffective provisions. In the event of contradictions between
                this DPA and any other agreements between the Parties,
                especially the Agreement, the provisions of this DPA shall take
                precedence. Ancillary agreements, amendments, and additions to
                this DPA must be made in writing. This also applies to the
                amendment of this requirement for written form.
              </p>

              <h2>Appendix 1: Personal data</h2>
              <h3>Personal Data Sport AI processes as Controller (Sport AI Console)</h3>
              <h4>Categories of data subjects</h4>
              <p>
                The personal data transferred concern the following categories
                of data subjects: The Client’s representatives and Client's
                Authorized users.
              </p>

              <h4>Categories of personal data</h4>
              <p>
                The personal data transferred concern the following categories
                of data:
                <ul>
                  <li>
                    Contact information: full name, e-mail addresses, phone
                    numbers, and other ways in which Sport AI can contact the
                    data subject
                  </li>
                  <li>
                    Communications: any communication Client has with Sport AI,
                    like emails and phone calls
                  </li>
                  <li>
                    Information regarding the usage of the Sport AI Services,
                    like login information via Google Account, payment
                    transactions and technical connection data (IP address,
                    location, logs, etc.)
                  </li>
                </ul>
              </p>

              <h4>Sensitive Categories</h4>
              <p>
                No sensitive categories of personal data will be transferred as
                part of the applicable services.
              </p>

              <h4>Processing operations</h4>
              <p>
                The personal data transferred will be processed in accordance
                with the Agreement and may be subject to the following
                processing activities:
                <ul>
                  <li>
                    Storage and other processing necessary to provide, maintain,
                    and update the Sport AI Services provided to the Client
                  </li>
                  <li>To provide technical support to the Client</li>
                  <li>To administer invoicing and process payment</li>
                  <li>Disclosures in accordance with the Agreement, as compelled by law</li>
                </ul>
              </p>

              <h4>Duration of processing</h4>
              <p>
                Sport AI will process personal data as a controller for as long
                as needed to provide the Sport AI Services under the Agreement
                or as required by law.
              </p>

              <h3>Personal Data Sport AI processes as Processor (Sport AI Technical Analysis)</h3>
              <h4>Categories of data subjects</h4>
              <p>
                The personal data transferred concern the following categories
                of data subjects: The individuals in each video uploaded to
                Sport AI for analysis.
              </p>

              <h4>Categories of personal data</h4>
              <p>
                The personal data transferred concern the following categories
                of data:
                <ul>
                  <li>
                    Metadata that identify the location and timestamp of the
                    videos
                  </li>
                  <li>
                    Video recording of the individuals, their movements, and
                    metrics that are relevant for technical analysis such as
                    height and length of the individual and their limbs, body
                    structure, posture, and similar data.
                  </li>
                </ul>
              </p>

              <h4>Sensitive Categories</h4>
              <p>
                The personal data transferred concern the following special
                categories of data: None.
              </p>

              <h4>Processing operations</h4>
              <p>
                The personal data transferred will be processed in accordance
                with the Agreement and may be subject to the following
                processing activities:
                <ul>
                  <li>
                    Metadata will be scrubbed as the initial part of processing
                    the videos
                  </li>
                  <li>
                    Individuals in the video found to not be an intended part of
                    the recording, outside the courts will be blurred
                  </li>
                  <li>
                    The video recording, movements of the individual, body,
                    posture and similar data will be analysed with the purpose
                    of providing a technical analysis of the player only
                  </li>
                </ul>
              </p>

              <h4>Duration of processing</h4>
              <p>
                Sport AI will process personal data as a processor for the
                duration outlined in clause 7 of this DPA.
              </p>

              <h2>Appendix 2: Technical and Organisational Measures</h2>
              <p>
                Sport AI has implemented comprehensive organisational and
                technological measures to ensure the safety of personal data as
                well as undisturbed operation in an optimal manner.
              </p>

              <h3>Admission control</h3>
              <p>
                Measures to prevent unauthorised persons from gaining access to
                the data processing equipment used to process personal data.
                <ul>
                  <li>Access control guidelines and regulations</li>
                  <li>
                    Access only for authorised persons (company employees and
                    external persons)
                  </li>
                  <li>External staff is accompanied by Sport AI staff</li>
                </ul>
              </p>

              <h3>Access control</h3>
              <p>
                Measures and procedures to prevent unauthorised persons from
                using the data processing equipment.
                <ul>
                  <li>
                    Regulation of user authorizations (administration incl.
                    assignment of rights, assignment of special rights,
                    revocation of authorizations, regular reviews).
                  </li>
                  <li>Password policy (secure passwords, regular changes, regular reviews).</li>
                </ul>
              </p>

              <h3>Access monitoring</h3>
              <p>
                Measures to ensure that those authorised for data processing can
                only access the personal data subject to their access
                authorization.
                <ul>
                  <li>
                    Control of access authorization (differentiated
                    authorizations via profiles, roles, time limit)
                  </li>
                  <li>Provision of appropriate authentication technologies</li>
                  <li>Security Logs (ex: unsuccessful and successful authentication attempts).</li>
                  <li>Guidelines for the pseudonymization/anonymization of personal data</li>
                </ul>
              </p>

              <h3>Transfer control</h3>
              <p>
                Measures to ensure that personal data cannot be read, copied,
                altered or removed without authorisation during electronic
                transmission, transport or storage on data carriers.
                <ul>
                  <li>Guidelines for the exchange of information of all kinds</li>
                  <li>Encryption during data transmission and at rest (network encryption, TLS)</li>
                  <li>Logging during the transmission of data</li>
                  <li>Method for detecting and protecting malware</li>
                  <li>Access Control</li>
                  <li>Controlled destruction of data</li>
                </ul>
              </p>

              <h3>Input control</h3>
              <p>
                Measures to ensure authenticated entry of personal data.
                <ul>
                  <li>Access control</li>
                  <li>Data security policy</li>
                  <li>Process, program and workflow organisation</li>
                </ul>
              </p>

              <h3>Order control</h3>
              <p>
                Measures to ensure that personal data is processed within the
                boundaries and conditions as set out in this DPA.
                <ul>
                  <li>
                    Contract in writing with determination of the data
                    protection agreements
                  </li>
                  <li>Formalised order placement</li>
                  <li>Careful selection of the subcontractor</li>
                  <li>Monitoring the proper execution of the contract</li>
                  <li>Separation of duty</li>
                </ul>
              </p>

              <h3>Availability control</h3>
              <p>
                Measures to ensure that personal data is protected against
                accidental destruction or loss.
                <ul>
                  <li>Controlled process to ensure business operations (BCM)/IT-SCM</li>
                  <li>Contingency plans</li>
                  <li>Regular back-ups according to a backup plan</li>
                  <li>Protection of systems against database failure, service level agreements with IT service providers</li>
                  <li>Antivirus/Firewall</li>
                  <li>Redundant hardware</li>
                </ul>
              </p>

              <h3>Separation control</h3>
              <p>
                Measures to ensure that data collected for different purposes
                can be processed separately.
                <ul>
                  <li>Client separation</li>
                  <li>Functional separations</li>
                </ul>
              </p>

              <h3>Procedures for periodic review and evaluation</h3>
              <p>
                Procedures for regular review, evaluation and evaluation of the
                effectiveness of technical and organisational measures.
                <ul>
                  <li>Data Protection Management</li>
                  <li>Incident response management</li>
                </ul>
              </p>

              <h2>Appendix 3: Contact details of the parties</h2>
              <h3>Authorised representatives of the parties on data protection matters</h3>
              <p>
                <strong>Sport AI’s Data Protection Officer</strong>
                <br />
                Name: Felipe Longé
                <br />
                Email: felipe@sportai.com
              </p>

              <h2>Appendix 4: Subprocessors</h2>
              <h3>Subprocessors for Sport AI Console</h3>
              <ul>
                <li>Google LLC or its affiliates, US, Google Analytics</li>
                <li>Vercel Inc, US, Hosting and Infrastructure</li>
                <li>Clerk Inc, US, Infrastructure</li>
              </ul>

              <h3>Subprocessors for Sport AI Technical Analysis</h3>
              <ul>
                <li>Amazon Web Services (“AWS), US, Hosting and Infrastructure</li>
              </ul>
            </section>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default DataProcessingAgreement;
