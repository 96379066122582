import React from "react";

const BroadcastAndCommentary = () => {
  return (
    <section className="section">
      <div className="container" style={{ maxWidth: "720px", margin: "0 auto" }}>
        <div className="featured-box-icon text-primary text-22 text-center">
          <i className="fas fa-tv" />
        </div>
        <br />
        <h2 className="text-10 fw-600 text-center mb-5">
          Broadcast and Commentary
        </h2>
        <h3 className="text-6 fw-600 text-center mb-5">
          SportAI revolutionizes live sports with data-backed commentary, enhancing broadcasts and viewer engagement.        </h3>
        <img src="/images/console-broadcast.jpg" alt="Coaches and Training Facilities" className="img-fluid mx-auto d-block" style={{ maxWidth: 500 }} />
        <br />
        <p>
          Live sports with expert commentary is unparalleled, and it's only getting better with enhanced viewing experiences like live data and game stats. However, technique analysis often relies on subjective commentary. Using the SportAI platform, broadcasters can provide fast technique analysis comparing players with each other, or with themselves, from months and years gone by. Imagine seeing exactly how Federer and Borg compare on technique and how Sabalenka's style has evolved over the years.
        </p>
        <p>
          Commentators and broadcasters are fed with fast, objective analysis from the SportAI platform, which they can use to enhance their commentary and provide solid data-backed technique analysis to viewers worldwide. Perfect for studio highlights or for filling time between the action. Find out how you can boost your broadcast experience today.
        </p>
      </div>
    </section>
  );
};

export default BroadcastAndCommentary;
