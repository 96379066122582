import React from "react";
import { Link } from "react-router-dom";

const OurCustomers = () => {
  const services = [
    {
      name: "Coaches and Training Facilities",
      desc: "",
      icon: "fas fa-video",
      route: "/customers/coaches-and-training-facilities",
    },
    {
      name: "Broadcast & Commentary",
      desc: "",
      icon: "fas fa-tv",
      route: "/customers/broadcast-and-commentary",
    },
    {
      name: "Sports Equipment Brands & Retailers",
      desc: "",
      icon: "fas fa-golf-ball",
      route: "/customers/sports-equipment-brands-and-retailers",
    }
  ];

  return (
    <section id="our-customers" className="section bg-light">
      <div className="container">
        {/* Heading */}
        <h2 className="text-10 fw-600 text-center mb-5 wow fadeInUp">
          Our Customers
        </h2>
        <p className="text-5 text-center">
            Businesses can subscribe to our technology and integrate it into their offering, whether it is broadcast coverage, a training facility, a training app or a product recommendation site. Explore how our technology empowers different sectors:</p>
        {/* Heading end*/}
        <div className="row gy-5 mt-2">
          {services.length > 0 &&
            services.map((service, index) => (
              <div className="col-sm-6 col-lg-4 wow fadeInUp" key={index}>
                <Link to={service.route}>
                  <div className="featured-box text-center px-md-4 sai-hover-expand">
                    <div className="featured-box-icon text-primary text-13">
                      {" "}
                      <i className={service.icon} />
                    </div>
                    <h3 className="text-6 fw-600 mb-3" style={{ color: 'black' }}>{service.name}</h3>
                    <p className="mb-0 fw-300">{service.desc}</p>
                  </div>
                </Link>
              </div>
            ))}
        </div>
        <div className="row gy-5 mt-2">
        <p className="text-5 text-center">
        Our versatile platform caters to a diverse range of applications, from enhancing broadcast coverage with in-depth analytics to transforming training facilities with advanced technique analysis. Whether integrated into a cutting-edge training app or utilized for insightful product recommendations, our solution is designed to elevate the sports industry standard.</p>
        </div>
        
      </div>
    </section>
  );
};

export default OurCustomers;
