import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const DevOps = () => {
    return (
        <section id="news-article" className="section bg-lig text-black">
            <Container>
                <Row className="justify-content-md-center">
                    <Col md={12} className="text-center">
                        <img
                            src="../../images/intro-bg.jpg"
                            alt="job-ad-image"
                            style={{ maxHeight: "50vh" }}
                            className="img-fluid"
                        />
                    </Col>
                </Row>
                <Row className="justify-content-md-center">
                    <Col lg={6} md={8}>
                        <header className="article-header mt-5">
                            <h1 className="article-title">
                                Development Operations
                            </h1>
                            <div className="article-meta mt-3">
                                <time className="small text-muted" dateTime="2024-01-XX">
                                    Location: Oslo, Norway
                                </time>
                            </div>
                        </header>
                        <section className="article-content mt-3">
                            <h4 className="article-subtitle" style={{ lineHeight: 1.5 }}>
                                SportAI has an opening for a DevOps Engineer to join our ambitious technical team in Oslo.
                                <br />
                                The ideal candidate will have a strong background in infrastructure management and continuous improvement of complex software. You will be responsible for developing and implementing infrastructure, tools, and defining processes that unblock and help the rest of the team excel in their goal of deploying quality software often and reliably.
                                <br />
                                This role offers a unique opportunity to work with cutting-edge technology and make a notable impact on the sports industry.
                            </h4>
                            <h5 className="mt-5 fw-600">About our Company and Culture:</h5>
                            <p>
                            SportAI is a new sports technology company specialising in SaaS solutions for the sports industry. We are pioneers in advanced computer vision and machine learning, delivering instant and precise sports technique analysis. We are now ready to expand our team with a new colleague passionate about sports, innovation, and technology. If you are a self-starter who thrives in a driven and highly energetic work environment, we would love to hear from you!
                            </p>
                            <h5 className="mt-5 fw-600">Responsibilities:</h5>
                            <ul>
                                <li>Manage IT infrastructure in cloud environments (e.g. AWS)</li>
                                <li>Automate processes using CI/CD pipelines and workflows</li>
                                <li>Help reduce friction and lead time in rolling out software</li>
                                <li>Monitor and maintain high availability of production systems</li>
                                <li>Collaborate across teams to balance needs throughout the software development lifecycle</li>
                                <li>Develop and manage tools to reduce manual effort in the release cycle</li>
                                <li>Contribute to designing and implementing new AI features and capabilities through collaboration with other engineers, designers, and domain experts</li>
                                <li>Contribute to finding solutions to complex computer vision challenges</li>
                            </ul>
                            <h5 className="mt-5 fw-600">Requirements:</h5>
                            <ul>
                                <li><b>CI/CD</b>: You must have extensive experience in setting up CI/CD from scratch, and deep experience with GitHub Actions is highly preferable.</li>
                                <li><b>NodeJS and TypeScript</b>: You must have a strong knowledge of the NodeJS and TypeScript ecosystem. Experience with PNPM (or Yarn) in a monorepo is a big plus.</li>
                                <li><b>Infrastructure as Code</b>: You should have experience with infrastructure as code. Experience with Pulumi is preferred, but familiarity with Terraform or similar tools is also favorable.</li>
                                <li><b>Containerization</b>: Experience with containerization using Docker is a must, and experience with orchestration using Kubernetes or similar is a big plus.</li>
                                <li><b>Cloud Services</b>: You must have good experience in deploying containers to AWS, Google Cloud, or Azure.</li>

                            </ul>
                            <h5 className="mt-5 fw-600">Bonus (experience with):</h5>
                            <ul>
                                <li><b>Python</b>: You should have good experience with Python and its ecosystem. It is a big plus if you have experience with, for example, Poetry.</li>
                                <li><b>Versioning</b>: Experience with tracking changes in microservice architectures with versioning, for example using tools like release-please.</li>
                                <li><b>Communication</b>: Ability to create overview and routines that promote a self-service culture for the rest of the team.</li>
                                <li><b>Deployment Routines</b>: Experience with the implementation and use of tools like feature flags to roll out functionality gradually is a big plus.</li>

                            </ul>
                            <h5 className="mt-5 fw-600">Benefits:</h5>
                            <ul>
                                <li>Competitive salary</li>
                                <li>An opportunity to own shares in the company</li>
                                <li>Flexible working schedules to balance professional and personal life</li>
                                <li>A commitment to continuous professional growth and skill development</li>
                                <li>A vibrant and positive team of colleagues and regular team building events</li>
                                <li>A centrally located, modern, and well-equipped office space in Aker Brygge, Oslo (Norway)</li>
                            </ul>
                            <h5 className="mt-5 fw-600">How to Apply:</h5>
                            <p>
                                The candidates interested in becoming an important team member, can submit their CV and a cover letter to: <a href="mailto:jobs@sportai.com?subject=MLOps%20job">jobs@sportai.com</a>. We are excited to hear from you and welcome you to SportAI!
                            </p>
                        </section>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default DevOps;