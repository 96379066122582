import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const TermsOfUse = () => {
  return (
    <section id="terms-of-use" className="section bg-lig text-black">
      <Container>
        <Row className="justify-content-md-center">
          <Col lg={8} md={10}>
            <header className="terms-header mt-5">
              <h1 className="terms-title">Terms of Use</h1>
              <p className="mt-4">Last updated: August 2, 2024</p>
            </header>

            <section className="terms-content mt-5">
              <h2>1. Agreement to Terms</h2>
              <p>
                These Terms of Use constitute a legally binding agreement made
                between you, whether personally or on behalf of an entity
                (“you”) and SportAI ("we", "us", or "our"), concerning your
                access to and use of the sportai.no website as well as any other
                media form, media channel, mobile website, or mobile application
                related, linked, or otherwise connected thereto (collectively,
                the “Site”). You agree that by accessing the Site, you have read,
                understood, and agree to be bound by all of these Terms of Use.
                If you do not agree with all of these Terms of Use, then you are
                expressly prohibited from using the Site and you must discontinue
                use immediately.
              </p>

              <h2>2. Intellectual Property Rights</h2>
              <p>
                Unless otherwise indicated, the Site is our proprietary property
                and all source code, databases, functionality, software, website
                designs, audio, video, text, photographs, and graphics on the Site
                (collectively, the “Content”) and the trademarks, service marks,
                and logos contained therein (the “Marks”) are owned or controlled
                by us or licensed to us, and are protected by copyright and
                trademark laws and various other intellectual property rights and
                unfair competition laws of the United States, international
                copyright laws, and international conventions. The Content and
                the Marks are provided on the Site “AS IS” for your information
                and personal use only. Except as expressly provided in these
                Terms of Use, no part of the Site and no Content or Marks may be
                copied, reproduced, aggregated, republished, uploaded, posted,
                publicly displayed, encoded, translated, transmitted, distributed,
                sold, licensed, or otherwise exploited for any commercial purpose
                whatsoever, without our express prior written permission.
              </p>

              <h2>3. User Representations</h2>
              <p>
                By using the Site, you represent and warrant that: (1) all
                registration information you submit will be true, accurate,
                current, and complete; (2) you will maintain the accuracy of such
                information and promptly update such registration information as
                necessary; (3) you have the legal capacity and you agree to comply
                with these Terms of Use; (4) you are not a minor in the
                jurisdiction in which you reside; (5) you will not access the Site
                through automated or non-human means, whether through a bot,
                script, or otherwise; (6) you will not use the Site for any
                illegal or unauthorized purpose; and (7) your use of the Site will
                not violate any applicable law or regulation.
              </p>

              <h2>4. Prohibited Activities</h2>
              <p>
                You may not access or use the Site for any purpose other than that
                for which we make the Site available. The Site may not be used in
                connection with any commercial endeavors except those that are
                specifically endorsed or approved by us. As a user of the Site, you
                agree not to:
              </p>
              <ul>
                <li>Systematically retrieve data or other content from the Site to create or compile, directly or indirectly, a collection, compilation, database, or directory without written permission from us.</li>
                <li>Make any unauthorized use of the Site, including collecting usernames and/or email addresses of users by electronic or other means for the purpose of sending unsolicited email, or creating user accounts by automated means or under false pretenses.</li>
                <li>Use the Site to advertise or offer to sell goods and services.</li>
                <li>Engage in unauthorized framing of or linking to the Site.</li>
                <li>Trick, defraud, or mislead us and other users, especially in any attempt to learn sensitive account information such as user passwords.</li>
                <li>Engage in any automated use of the system, such as using scripts to send comments or messages, or using any data mining, robots, or similar data gathering and extraction tools.</li>
                <li>Interfere with, disrupt, or create an undue burden on the Site or the networks or services connected to the Site.</li>
                <li>Attempt to impersonate another user or person or use the username of another user.</li>
                <li>Sell or otherwise transfer your profile.</li>
                <li>Use any information obtained from the Site in order to harass, abuse, or harm another person.</li>
                <li>Use the Site as part of any effort to compete with us or otherwise use the Site and/or the Content for any revenue-generating endeavor or commercial enterprise.</li>
                <li>Decipher, decompile, disassemble, or reverse engineer any of the software comprising or in any way making up a part of the Site.</li>
                <li>Attempt to bypass any measures of the Site designed to prevent or restrict access to the Site, or any portion of the Site.</li>
                <li>Harass, annoy, intimidate, or threaten any of our employees or agents engaged in providing any portion of the Site to you.</li>
                <li>Delete the copyright or other proprietary rights notice from any Content.</li>
                <li>Copy or adapt the Site’s software, including but not limited to Flash, PHP, HTML, JavaScript, or other code.</li>
                <li>Upload or transmit (or attempt to upload or to transmit) viruses, Trojan horses, or other material, including excessive use of capital letters and spamming (continuous posting of repetitive text), that interferes with any party’s uninterrupted use and enjoyment of the Site or modifies, impairs, disrupts, alters, or interferes with the use, features, functions, operation, or maintenance of the Site.</li>
                <li>Upload or transmit (or attempt to upload or to transmit) any material that acts as a passive or active information collection or transmission mechanism, including without limitation, clear graphics interchange formats (“gifs”), 1×1 pixels, web bugs, cookies, or other similar devices (sometimes referred to as “spyware” or “passive collection mechanisms” or “pcms”).</li>
                <li>Except as may be the result of standard search engine or Internet browser usage, use, launch, develop, or distribute any automated system, including without limitation, any spider, robot, cheat utility, scraper, or offline reader that accesses the Site, or using or launching any unauthorized script or other software.</li>
                <li>Disparage, tarnish, or otherwise harm, in our opinion, us and/or the Site.</li>
                <li>Use the Site in a manner inconsistent with any applicable laws or regulations.</li>
              </ul>

              <h2>5. User Generated Contributions</h2>
              <p>
                The Site may invite you to chat, contribute to, or participate in
                blogs, message boards, online forums, and other functionality, and
                may provide you with the opportunity to create, submit, post,
                display, transmit, perform, publish, distribute, or broadcast
                content and materials to us or on the Site, including but not
                limited to text, writings, video, audio, photographs, graphics,
                comments, suggestions, or personal information or other material
                (collectively, "Contributions"). Contributions may be viewable by
                other users of the Site and through third-party websites. As such,
                any Contributions you transmit may be treated as non-confidential
                and non-proprietary. When you create or make available any
                Contributions, you thereby represent and warrant that:
              </p>
              <ul>
                <li>The creation, distribution, transmission, public display, or performance, and the accessing, downloading, or copying of your Contributions do not and will not infringe the proprietary rights, including but not limited to the copyright, patent, trademark, trade secret, or moral rights of any third party.</li>
                <li>You are the creator and owner of or have the necessary licenses, rights, consents, releases, and permissions to use and to authorize us, the Site, and other users of the Site to use your Contributions in any manner contemplated by the Site and these Terms of Use.</li>
                <li>You have the written consent, release, and/or permission of each and every identifiable individual person in your Contributions to use the name or likeness of each and every such identifiable individual person to enable inclusion and use of your Contributions in any manner contemplated by the Site and these Terms of Use.</li>
                <li>Your Contributions are not false, inaccurate, or misleading.</li>
                <li>Your Contributions are not unsolicited or unauthorized advertising, promotional materials, pyramid schemes, chain letters, spam, mass mailings, or other forms of solicitation.</li>
                <li>Your Contributions are not obscene, lewd, lascivious, filthy, violent, harassing, libelous, slanderous, or otherwise objectionable (as determined by us).</li>
                <li>Your Contributions do not ridicule, mock, disparage, intimidate, or abuse anyone.</li>
                <li>Your Contributions do not advocate the violent overthrow of any government or incite, encourage, or threaten physical harm against another.</li>
                <li>Your Contributions do not violate any applicable law, regulation, or rule.</li>
                <li>Your Contributions do not violate the privacy or publicity rights of any third party.</li>
                <li>Your Contributions do not contain any material that solicits personal information from anyone under the age of 18 or exploits people under the age of 18 in a sexual or violent manner.</li>
                <li>Your Contributions do not violate any applicable law concerning child pornography, or otherwise intended to protect the health or well-being of minors.</li>
                <li>Your Contributions do not include any offensive comments that are connected to race, national origin, gender, sexual preference, or physical handicap.</li>
                <li>Your Contributions do not otherwise violate, or link to material that violates, any provision of these Terms of Use, or any applicable law or regulation.</li>
              </ul>

              <h2>6. Contribution License</h2>
              <p>
                By posting your Contributions to any part of the Site or making
                Contributions accessible to the Site by linking your account from
                the Site to any of your social networking accounts, you
                automatically grant, and you represent and warrant that you have
                the right to grant, to us an unrestricted, unlimited, irrevocable,
                perpetual, non-exclusive, transferable, royalty-free, fully-paid,
                worldwide right, and license to host, use, copy, reproduce,
                disclose, sell, resell, publish, broadcast, retitle, archive,
                store, cache, publicly perform, publicly display, reformat,
                translate, transmit, excerpt (in whole or in part), and distribute
                such Contributions (including, without limitation, your image and
                voice) for any purpose, commercial, advertising, or otherwise, and
                to prepare derivative works of, or incorporate into other works,
                such Contributions, and grant and authorize sublicenses of the
                foregoing. The use and distribution may occur in any media formats
                and through any media channels.
              </p>

              <h2>7. Modifications and Interruptions</h2>
              <p>
                We reserve the right to change, modify, or remove the contents of
                the Site at any time or for any reason at our sole discretion
                without notice. However, we have no obligation to update any
                information on our Site. We also reserve the right to modify or
                discontinue all or part of the Site without notice at any time. We
                will not be liable to you or any third party for any modification,
                price change, suspension, or discontinuance of the Site.
              </p>
              <p>
                We cannot guarantee the Site will be available at all times. We
                may experience hardware, software, or other problems or need to
                perform maintenance related to the Site, resulting in
                interruptions, delays, or errors. We reserve the right to change,
                revise, update, suspend, discontinue, or otherwise modify the Site
                at any time or for any reason without notice to you. You agree
                that we have no liability whatsoever for any loss, damage, or
                inconvenience caused by your inability to access or use the Site
                during any downtime or discontinuance of the Site. Nothing in
                these Terms of Use will be construed to obligate us to maintain
                and support the Site or to supply any corrections, updates, or
                releases in connection therewith.
              </p>

              <h2>8. Governing Law</h2>
              <p>
                These Terms shall be governed by and defined following the laws of
                Norway. SportAI and yourself irrevocably consent that the courts
                of Norway shall have exclusive jurisdiction to resolve any dispute
                which may arise in connection with these terms.
              </p>

              <h2>9. Contact Us</h2>
              <p>
                In order to resolve a complaint regarding the Site or to receive
                further information regarding the use of the Site, please contact
                us at:
              </p>
              <p>
                SportAI
                <br />
                Email: support@sportai.no
                <br />
                Phone: +47 464 23 779
                <br />
                Address: Tordenskiolds gate 2, 4th Floor, 0160 Oslo, Norway.
              </p>
            </section>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default TermsOfUse;
