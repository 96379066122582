import React from "react";

const FullScreenDefaultIntro = () => {
  return (
    <section
      id="home"
      className="bg-primary d-flex fullscreen position-relative py-5"
    >
    </section>
  );
};

export default FullScreenDefaultIntro;
