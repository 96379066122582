import React from "react";

const CoachesAndTrainingFacilities = () => {
  return (
    <section className="section">
      <div className="container" style={{ maxWidth: "720px", margin: "0 auto" }}>
        <div className="featured-box-icon text-primary text-22 text-center">
          <i className="fas fa-video" />
        </div>
        <br/>
        <h2 className="text-10 fw-600 text-center mb-5">
          Coaches and Training Facilities
        </h2>
        <h3 className="text-6 fw-600 text-center mb-5">
          SportAI transforms coaching with automated analysis and insights, boosting revenue and loyalty.
        </h3>
        <img src="/images/console-facilities.jpg" alt="Coaches and Training Facilities" className="img-fluid mx-auto d-block" style={{ maxWidth: 500}}/>
        <br/>
        <p>
          Coaches and training facilities can scale their offering to provide automatic video match highlights, technique analysis, training feedback and game statistics using the SportAI platform.
          With basic, low-cost cameras mounted at the back of a court, or using video from a mobile phone, players can practise their technique and gather valuable data between coaching sessions.
        </p>
        <p>
          Training facilities can offer subscriptions or one time video analysis fees to develop new revenue streams and create loyal customers.</p>
        <p>
          SportAI can deliver the analysis and data through an API to existing training apps or provide a white-labelled app for training facilities to brand themselves. SportAI can take care of everything - get in touch to find out how you can offer value added services to your customer base.
        </p>
      </div>
    </section>
  );
};

export default CoachesAndTrainingFacilities;
