import React from "react";

const SportsEquipmentBrandsAndRetailers = () => {
  return (
    <section className="section">
      <div className="container" style={{ maxWidth: "720px", margin: "0 auto" }}>
        <div className="featured-box-icon text-primary text-22 text-center">
          <i className="fas fa-golf-ball" />
        </div>
        <br/>
        <h2 className="text-10 fw-600 text-center mb-5">
          Sports Equipment Brands and Retailers
        </h2>
        <h3 className="text-6 fw-600 text-center mb-5">
        SportAI simplifies sports equipment shopping with personalized, data-driven recommendations, enhancing customer satisfaction and brand loyalty.
        </h3>
        <img src="/images/console-equipment.jpg" alt="Coaches and Training Facilities" className="img-fluid mx-auto d-block" style={{ maxWidth: 500}}/>
        <br/>
        <p>
        Choosing the right sports equipment can be overwhelming due to the plethora of options available. SportAI simplifies this by offering personalized equipment recommendations based on a short video clip of the customer's gameplay. 
        </p>
        <p>
        Users can upload video or capture their tennis or padel swing and receive a tailored list of racket or other equipment suggestions that match their playing style, skill level, and body type. This not only helps players make confident choices but also boosts sales for brands and retailers while fostering customer relationships and future upselling possibilities.
        </p>
      </div>
    </section>
  );
};

export default SportsEquipmentBrandsAndRetailers;
