import React from "react";

const Team = () => {
  const team = [
    {
      "name": "Lauren Pedersen",
      "title": "Chief Executive Officer",
      "desc": "Extensive experience growing global tech firms. Represented Norway at the 2023 ITF Tennis Masters World Champs.",
      "imageName": "team-laurenpedersen.png"
    },
    {
      "name": "Felipe Longé",
      "title": "Chief Technical Officer",
      "desc": "Seasoned tech entrepreneur with over 15 years of experience. Former CTO of Play Magnus.",
      "imageName": "team-felipelonge.png"
    },
    {
      "name": "Trond Kittelsen",
      "title": "Commercial and Operations",
      "desc": "Serial entrepreneur and sports tech product expert. CEO of Sevensix Tennis.",
      "imageName": "team-trondkittelsen.png"
    }, 
    {
      "name": "Lejla Thome",
      "title": "Marketing",
      "desc": "M.Sc. in Business and Marketing. International working experience within academia and private sector.",
      "imageName": "team-lejlathome.png"
    },
    {
      "name": "Tord Romstad",
      "title": "Technical",
      "desc": "Data Scientist and Mathematician. Author of Stockfish, the world's strongest chess engine.",
      "imageName": "team-tordromstad.png"
    },
    {
      "name": "Martin Hovin",
      "title": "Technical",
      "desc": "M.Sc. Robotics & Intelligent Systems. Machine Learning and Computer Vision expert.",
      "imageName": "team-martinhovin.png"
    },
    {
      "name": "Lars Kristian Telle",
      "title": "Technical",
      "desc": "M.Sc. Computer Science. Full-stack Developer, Machine Learning Engineer, and API architect.",
      "imageName": "team-larskristiantelle.png"
    },
    {
      "name": "Daniil Shantsev",
      "title": "Technical",
      "desc": "PhD in Physics. Numerical methods, modeling and machine learning expert.",
      "imageName": "team-daniilshantsev.png"
    },
    {
      "name": "Håvard Næss",
      "title": "Technical",
      "desc": "BBA graduate and currently studying for an M.Sc. in Data Science & AI. Reigning National Students’ Tennis Champion.",
      "imageName": "team-haavardnaess.png"
    },
    {
      "name": "Esten Leonhardsen",
      "title": "Technical Advisor",
      "desc": "Ph.D. Neuropsychology. Data Scientist and Entrepreneur.",
      "imageName": "team-estenleonhardsen.png"
    },
    {
      "name": "Shiven Mehta",
      "title": "Summer Intern",
      "desc": "Aspiring Engineer and Competitive Tennis Player.",
      "imageName": "team-shivenmehta.png"
    }
  ];  

  const board = [
    {
      "name": "Andreas Thome",
      "title": "Chairman",
      "desc": "Extensive experience from Internet, Software and Telecom businesses globally. Former CEO of Play Magnus.",
      "imageName": "team-andreasthome.png"
    },
    {
      "name": "Espen Agdestein",
      "title": "Board Member",
      "desc": "Co-Founder of Play Magnus and Partner at Idékaptial. Serial Entrepreneur with multiple exits.",
      "imageName": "team-espenagdestein.png"
    },
    {
      "name": "Kurt Bakkevig",
      "title": "Board Member",
      "desc": "Legal and Business Development. Former CEO of Biometrical.",
      "imageName": "team-kurtbakkevig.png"
    },
    {
      "name": "Magnus Carlsen",
      "title": "Advisor",
      "desc": "Five time world chess champion and the highest rated chess player in history.",
      "imageName": "team-magnuscarlsen.png"
    }
  ];  

  return (
    <section id="team" className="section bg-white">
      <div className="container">
        <h2 className="text-10 fw-600 text-center mb-5 wow fadeInUp">
          The Dream Team
        </h2>
        <div className="row gy-5 mt-5">
          {team.length > 0 &&
            team.map((person, index) => (
              <div className="col-6 col-lg-3 wow fadeInUp" key={index}>
                <div className="featured-box text-center px-md-4">
                  <div className="featured-box-icon text-primary text-13">
                    {" "}
                    <img
                      height={120}
                      className="rounded-circle bg-dark-green"
                      src={`images/${person.imageName}`}
                      alt=""></img>
                  </div>
                  <h3 className="text-3 fw-300 mb-2">{person.name}</h3>
                  <h4 className="text-3 fw-500 mb-2">{person.title}</h4>
                  <p className="fw-300 mb-0 d-none d-md-block">{person.desc}</p>
                </div>
              </div>
            ))}
        </div>
      </div>
      <div className="container">
        <h2 className="text-10 fw-600 text-center mb-5 mt-5 wow fadeInUp">
          Board and Advisors 
        </h2>
        <div className="row gy-5">
          {board.length > 0 &&
            board.map((person, index) => (
              <div className="col-6 col-lg-3 wow fadeInUp" key={index}>
                <div className="featured-box text-center px-md-4">
                  <div className="featured-box-icon text-primary text-13">
                    {" "}
                    <img
                      height={120}
                      className="rounded-circle bg-dark-green"
                      src={`images/${person.imageName}`}
                      alt=""></img>
                  </div>
                  <h3 className="text-3 fw-300 mb-2">{person.name}</h3>
                  <h4 className="text-3 fw-500 mb-2">{person.title}</h4>
                  <p className="fw-300 mb-0 d-none d-md-block">{person.desc}</p>
                </div>
              </div>
            ))}
        </div>
      </div>
    </section>
  );
};

export default Team;
