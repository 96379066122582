import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const WhyChessChampionInvestedInSportAI = () => {
    return (
        <section id="news-article" className="section bg-lig text-black">
            <Container>
                <Row className="justify-content-md-center">
                    <Col md={12} className="text-center">
                        <img
                            // src="../../images/blog-next-level-analytics.jpg"
                            src="../../images/blog-why-chess-champion-invested-in-sportai.png"
                            alt="blog-image"
                            style={{ maxHeight: "50vh" }}
                            className="img-fluid"
                        />
                        <p className="small text-muted">
                            Photo : Maria Emelianova/Chess.com
                        </p>
                    </Col>
                </Row>
                <Row className="justify-content-md-center">
                    <Col lg={6} md={8}>
                        <header className="article-header mt-5">
                            <h1 className="article-title" style={{textAlign: 'center'}}>
                            Why World Chess Champion Magnus Carlsen Invested In SportAI</h1>
                            <div className="article-meta mt-3">
                                <time className="small text-muted" dateTime="2024-01-XX">
                                    May 7, 2024 | Edward Radzivilovskiy 
                                </time>
                            </div>
                        </header>
                        <section className="article-content mt-3">
                        <h4 className="article-subtitle" style={{ lineHeight: 1.5 }}>
                        SportAI may be a young tech company, established in late 2023, but it landed a big fish right from the beginning:
                        <br/>
                        5-time World Chess Champion Magnus Carlsen became the lead investor in the company’s pre-seed round. Some in the market were curious to learn more about the company and how it captured Magnus’ attention.
                            </h4>
                            <h4 className="article-subtitle text-8 fw-300 mt-5 mb-4" style={{ lineHeight: 1.5 }}>
                            Interview with Magnus’ father and manager, Henrik Carlsen
                            </h4>
                            <p>
                            I recently had the chance to speak with Henrik Carlsen, Magnus’ father and manager. I decided to learn about why he and Magnus chose to be early investors in the new AI based sports-tech company called SportAI from the very start.
                            </p>
                            <p>
                            It might not immediately seem like an obvious fit, but during our one hour conversation, we discussed the relationship between chess and other sports, the importance of personalized coaching to improve performance, how artificial intelligence has played a big role in the development of computer chess and how that is destined to play out similarly in other sports.
                            </p>
                            <p>For starters, I wanted to find out how a World Chess Champion got involved with SportAI. As Henrik explained: <b>“There is a natural affinity between sports like chess and football”</b> emphasizing the strategic elements shared between them. In fact, according to Henrik, strategic thinking is an essential part of most sports. Inspired by Google DeepMind’s AlphaZero, Magnus knew from his personal experience that AI-based chess training has revolutionized the chess world. He realized that the sports world was poised to undergo a similar transformation. <b>“What better time to get in on the ground floor with an investment opportunity like SportAI?”</b>, he commented. </p>
                            <p>
                            Interestingly, three critical aspects were imperative for Magnus’ decision to invest. Firstly, Magnus had previously worked closely with the current leadership of SportAI (more specifically Andreas Thome, former CEO of Play Magnus who now is the Chairman for SportAI, as well as Magnus’ ex-manager Espen Agdestein, former Chairman of the Board in Play Magnus, now a Board member in SportAI) while scaling his own co-founded chess company, PlayMagnus AS. Henrik unequivocally added, <b>“From what I've seen from the team we have worked with before, we instantly knew that this enterprise is gonna be a success.”</b>
                            </p>
                            <p>
                            Secondly, Magnus felt very comfortable with the investment opportunity at SportAI due to the fact that not being just a chess player, Magnus has a passion for several other sports: these days he is an avid golfer and is also very much into soccer, possibly due to the fact that one of his first chess coaches - Simen Agdestein (whose brother Espen Agdestein is on on SportAI’s Board) was a former professional footballer and striker for the Norwegian national football team. Magnus loved  the fact that the main focus of SportAI is on swing based sports, such as golf, padel and tennis - all of which he knew enough about to be certain that AI could work perfectly.
                            </p>
                            <p>
                            Thirdly, Magnus’ long-term fascination with sports analytics (akin to the "Moneyball" approach) was decisive for the venture. In fact, what many don’t know is that Magnus was the top Fantasy Premier League player (free-to-play fantasy football game of the English Premier League with over 11 million users) at one time. In short,  Magnus loved the idea of how SportAI was using computer vision, machine learning data and AI-driven analytics to improve everyone’s potential in sports.
                            </p>
                            <p class="text-4 fw-600 p-3 pb-1">
                                <em>"What better time to get in on the ground floor with an investment opportunity like SportAI?"</em>
                            <br/>
                            <p className="text-6" style={{textAlign: 'right'}}>Henrik Carlsen</p>
                            </p>
                            <p>
                            On a more personal note, both Henrik and Magnus are  keen on working on boosting their own golf performance. Funnily enough, it seems like they are hopeful of utilizing SportAI technology to help improve their golf game enough to win over each  other. We’ll see. Perhaps the SportAI tech will allow them both to excel and stay well-matched top golf players instead.
                            </p>
                            <p>
                            He noted that Sport AI is all about  “AI and tech and how it helps us understand human movements, optimize them, and essentially improve sports technique performance", a perspective that clearly reflects a broader understanding of AI as a tool that enhances human capabilities rather than replacing them. Henrik also talked in detail about how AI had influenced chess in stages: it transformed from a tool for simple calculations to a profound component of strategic planning and analysis, an evolution that mirrors what Magnus and Henrik foresee with SportAI’s impact across various sports.
                            </p>
                            <p>
                            One of the most interesting and somewhat unexpected topics that emerged from our conversation was about how SportAI democritizes access to top level sports training. Suddenly the best training protocols in the world could be available to everyone, regardless of their athletic level and financial ability, compared to hiring an expert sports coach and paying for expensive (and highly subjective) one-on-one training sessions. Henrik spoke passionately about the importance of having top-level coaching and training insights acceessible to all, and I couldn't agree with him more.
                            </p>
                            <p>
                            These AI-based sports tools could help level the playing field and unearth many more tennis, golf and other sports talents across the globe. Henrik compared some of the large-scale changes for sports to some of Magnus’ early journeys, learning to play chess as a young man, saying that ubiquitous high speed internet access and the availability of online resources played a crucial role in Magnus’ development outside the traditional Russian chess powerhouse.
                            </p>
                            <p>
                            As I was wrapping up my interview with Henrik, I remember feeling very inspired and also grateful. Obviously, it’s difficult for Magnus to find time to do interviews. The fact that his father and manager decided to spend an hour sharing Magnus’ reasons for investment decision highlighted both their commitments to SportAI vision for creating a more athletic world. I had an opportunity to reach out to Magnus prior to this interview, and he expressed his enthusiasm for SportAI venture, stating:
                            </p>
                            <p class="text-4 fw-600 p-3 pb-1">
                                <em>"To be great at sports is about finding your edge. The technology that SportAI is bringing to market can expand availability to technical coaching and analysis in a way that’s never been possible before. I love sports like golf and tennis, and I believe that AI-powered technique analysis can truly advance these sports for players, businesses and spectators alike. That is why I decided to invest and get behind the SportAI team."</em>
                            <br/>
                            <p className="text-6" style={{textAlign: 'right'}}>Magnus Carlsen</p>
                            </p>
                            <p>
                            In our conversation, Henrik Carlsen not only highlighted the strategic and personal reasons behind the Carlsen family's investment in SportAI, but also illuminated their vision for the future. With a firm belief in the potential of AI to enhance human performance, the Carlsens are championing a new era in sports, making it an exciting field not just for athletes but for anyone interested in the intersection of technology, strategy, and human potential. I, for one, am glad to be onboard with them.
                            </p>
                            <br/>
                            <p>
                            If you're interested in joining the team, now is the time.
                            </p>
                            <br/>
                            <br/>
                            <p>
                            <i>For general inquiries and career opportunities, please contact us at <a href="contact@sportai.com">contact@sportai.com</a></i>
                            <br/>
                            <i>For investor related questions, please contact <a href="lauren.pedersen@sportai.com">lauren.pedersen@sportai.com</a></i> 
                            </p>
                        </section>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default WhyChessChampionInvestedInSportAI;