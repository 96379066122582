import React from "react";
const WhatWeDo = () => {
  return (
    // <section id="what-we-do" className="section">
    <section id="about" className="section">
      <div className="container">
        {/* Heading */}
        <h2 className="text-10 fw-600 text-center mb-5 wow fadeInUp">
          What We Do
        </h2>
        {/* Heading end*/}
        <div className="row">
          <div className="col-lg-7 text-center text-lg-start wow fadeInUp">
            <p className="text-5">
            We offer a unique platform to deliver instant and precise sport technique analysis.</p>
            <p className="text-5">
            Powered by computer vision and biometric technology, we analyze a player’s technique, such as a golf, tennis or padel swing. We compare this technique to thousands of other players including the top professionals, and deliver instant written and visual feedback to improve their performance.</p>
            <p className="text-5">
            This is the first time sports technique analysis has been objective and scalable.</p>
          </div>
          <div className="col-md-1"></div>
          <div
            className="col-lg-4 mt-4 mt-lg-0 wow fadeInUp"
            data-wow-delay="0.2s"
          >
            <img
              className="img-fluid sai-rounded"
              src="images/golf-player-slim.jpg"
              alt="What We Do"></img>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhatWeDo;
