import React, { useState } from "react";
import { Link } from "react-scroll";
import { scrollDuration } from "../../../config/commonConfig";
import { Tooltip } from "../../Tooltip";
import ReactGA from 'react-ga';

const StandardMenuHeaderLite = () => {
  const [isNavModalClose, setIsNavModalClose] = useState(true);

  return (
    <header id="header" className="sticky-top">
      {/* Navbar */}
      <nav className="primary-menu navbar navbar-expand-lg text-uppercase navbar-line-under-text fw-600">
        <div className="container position-relative">
          <div className="col-auto col-lg-2 d-inline-flex ps-lg-0">
            {/* Logo */}
            <Link
              className="logo"
              title="SportAI"
              smooth="easeInOutQuint"
              duration={scrollDuration}
              style={{ cursor: "pointer", height: "70px"}}
              offset={-72}
              onClick={(e) => {
                e.preventDefault();
                window.location.href = '/';
              }}
            >
              <img height={63} src="../../images/logo-dark-green.png" alt="Sport AI" />
            </Link>
            {/* Logo End */}
          </div>
        </div>
      </nav>
      {/* Navbar End */}
    </header>
  );
};

export default StandardMenuHeaderLite;
