import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import SignUpForm from "../SignUpForm";
import Contact from "../Contact";

const NorwegianTechLeaders = () => {
  return (
    <section id="news-article" className="section bg-lig text-black">
      <Container>
        <Row className="justify-content-md-center">
          <Col md={12} className="text-center">
            <img
              src="../../images/team.JPG"
              alt="news-image"
              style={{ maxHeight: "50vh" }}
              className="img-fluid"
            />
            <p className="small text-muted">
              Photo: Lauren Pedersen, Andreas Thome, Felipe Longé and Espen Agdestein.
            </p>
          </Col>
        </Row>
        <Row className="justify-content-md-center">
          <Col lg={6} md={8}>
            <header className="article-header mt-5">
              <h1 className="article-title">
                Norwegian Tech Leaders Unite to Launch AI-Powered Sports Tech Company
              </h1>
              <div className="article-meta mt-3">
                <time className="small text-muted" dateTime="2024-01-XX">
                  January 22, 2024
                </time>
              </div>
            </header>
            <section className="article-content mt-3">
              <h4 className="article-subtitle" style={{ lineHeight: 1.5 }}>
                A team of Norwegian innovators, including tech and software
                industry experts Andreas Thome, Lauren Pedersen, and Espen
                Agdestein have united to establish SportAI, a new sports
                technology company. Recently securing 8 MNOK in pre-seed funding
                from prominent early-stage investors including five-time world
                chess champion Magnus Carlsen, the company aims to transform
                sports technique analysis through the integration of
                cutting-edge AI technology.
              </h4>
              <h5 className="mt-5 fw-600">AI-Powered Sports Technology</h5>
              <p>
                Lauren Pedersen, CEO of SportAI explains:{" "}
                <span className="fst-italic">
                  "Conventional coaching, commentary, and analysis of sports
                  technique, from tennis to golf swings, has been subjective and
                  limited in scalability. Our technology disrupts this norm.
                  Leveraging computer vision and machine learning, we provide
                  real-time analysis of athletes' performances, offering
                  immediate data-driven feedback sourced from the world's elite
                  players."
                </span>
              </p>
              <p>
                SportAI aims to bring objectivity to sports technique analysis
                to benefit players, coaches, sports facilities, broadcasters,
                and equipment brands. The founding team includes highly skilled
                developers with expertise in machine learning, computer vision,
                neuropsychology, robotics, and data science, who have already
                been developing the technology for the past three years.{" "}
                <span className="fst-italic">
                  “We have two proof of concept projects already completed and
                  further pilot projects under discussion”
                </span>
                , adds Pedersen.
              </p>
            </section>
            <p>
              Andreas Thome, former CEO of Play Magnus, who assumes the Chairman
              role on the Board of Directors says:
            </p>
            <p className="fst-italic">
              "I'm thrilled to be part of this exceptional team, merging
              technological, commercial, and sporting expertise. The potential
              of AI in sports is enormous, as proven by 13.8 billion USD in
              global sports tech funding only in 2023[1]. Our collective
              experience and passion for sports tech are poised to deliver value
              across multiple segments within the industry. We're eager to
              commence our journey in 2024.”
            </p>
            <img
              src="../../images/team-2.jpg"
              alt="news-image"
              style={{ maxHeight: "50vh" }}
              className="img-fluid"
            />
            <p className="small text-muted">
              Photo: Lauren Pedersen, Andreas Thome, and Felipe Longé.
            </p>
            <h5 className="mt-5 fw-600">B2B Sportstech</h5>
            <p>
              Pedersen outlines the business model:{" "}
              <span className="fst-italic">
                "Businesses will subscribe to SportAI technology and integrate
                it into their offerings via an API, whether for broadcast
                coverage, training apps, or product recommendation sites."
              </span>
            </p>
            <p>
              The team plans to select pilot projects to further demonstrate and
              develop the technology in early 2024 before a full commercial
              launch.
            </p>
            <h5 className="mt-5 fw-600">About the Company</h5>
            <p>
              Founded in December 2023, SportAI is a Norwegian-founded sports
              technology company dedicated to advancing sports technique
              coaching, commentary, and analysis using AI. Building upon
              technology developed since 2020, the company offers proprietary
              technique analysis software as a service (SaaS) to sports
              facilities, coaches, broadcasters, and equipment brands worldwide.{" "}
            </p>

            <p>
              For more information please contact:
              <br />
              Lauren Pedersen
              <br />
              Chief Executive Officer | SportAI
              <br />
              <a className="text-black" href="lauren.pedersen@sportai.no">
                lauren.pedersen@sportai.com
              </a>
              <br />
              <a className="text-black" href="tel:004790663094">
                +47 906 63 094
              </a>
              <br />
              or{" "}
              <a className="text-black" href="contact@sportai.no">
                contact us
              </a>
            </p>
            <h6 className="mt-4 fw-600">Lauren Pedersen - CEO</h6>
            <p>
              Originally from New Zealand, Lauren Pedersen has led marketing and
              commercial teams at a host of successful tech companies since
              moving to Oslo 16 years ago, including Opera Software and
              Airthings. An experienced tennis player, Lauren played NCAA
              division one college tennis in the US and recently represented
              Norway in the ITF Masters Tennis World Championships.
            </p>
            <h6 className="mt-4 fw-600">
              Andreas Thome - Chairman of the Board
            </h6>
            <p>
              Norwegian executive with over 25 years of expertise in the tech
              and software industry. Ex-CEO of Play Magnus Group, Andreas Thome
              held various C-level positions at Opera Software. Presently he is
              actively engaged as Chairman in multiple prominent Nordic B2B SAAS
              companies that are supported by private equity (PE) and venture
              capital.{" "}
            </p>

            <h6 className="mt-4 fw-600">Espen Agdestein - Board Member</h6>
            <p>
              Espen is a serial entrepreneur with multiple business exits. He is
              a co-founder of Play Magnus and a Partner at Idékapital, Norway's
              largest Venture Capital Fund. Agdestein served as the former
              manager of Chess World Champion Magnus Carlsen, and is a FIDE
              Chess Master himself. Espen boasts extensive experience in various
              international investment, technology, and industrial networks.
            </p>
            <p className="small text-muted">
              1. Source: SportsTechX, VC report 2023
            </p>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default NorwegianTechLeaders;