import React from "react";

const Services = () => {
  // services details
  const services = [
    {
      name: "Action Recognition",
      desc: "Classify and recognize actions in real-time, such as a golf swing or a tennis serve.",
      icon: "fas fa-bolt",
    },
    {
      name: "AI Coach",
      desc: "Trained AI models to analyze sports technique and provide instant analysis.",
      icon: "fas fa-brain",
    },
    {
      name: "Recommendation Engine",
      desc: "Get tailored sports equipment recommendations based on playing style.",
      icon: "fas fa-dot-circle",
    },
    {
      name: "AI as a Service",
      desc: "Use our Cloud API to integrate our technology into your existing software or hardware solutions.",
      icon: "fas fa-cloud",
    },
    {
      name: "AI Vision",
      desc: "Multi-camera 3D tracking or single camera 2D tracking of athletes.",
      icon: "fas fa-eye",
    },
    {
      name: "Edge Computing",
      desc: "Run offline analysis in real-time with low latency using a new or existing camera setup.",
      icon: "fas fa-microchip",
    }
  ];

  return (
    <section id="services" className="section bg-dark-green">
      <div className="container text-white">
        {/* Heading */}
        <h2 className="text-10 fw-600 text-center mb-5 wow fadeInUp">
          The Technology
        </h2>
        <p className="text-5 text-center">
        At SportAI, we're redefining sports technique analysis through our advanced AI technology. Our platform's capabilities are designed not only to meet but exceed the evolving demands of the sports tech industry.</p>        
        {/* Heading end*/}
        <div className="row gy-5 mt-5">
          {services.length > 0 &&
            services.map((service, index) => (
              <div className="col-sm-6 col-lg-4 wow fadeInUp" key={index}>
                <div className="featured-box text-center px-md-4">
                  <div className="featured-box-icon text-primary text-13">
                    {" "}
                    <i className={service.icon} />
                  </div>
                  <h3 className="text-6 fw-600 mb-3">{service.name}</h3>
                  <p className="mb-0 fw-300">{service.desc} </p>
                </div>
              </div>
            ))}
        </div>
      </div>
    </section>
  );
};

export default Services;
