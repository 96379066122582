import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const PrivacyPolicy = () => {
  return (
    <section id="privacy-policy" className="section bg-lig text-black">
      <Container>
        <Row className="justify-content-md-center">
          <Col lg={8} md={10}>
            <header className="privacy-header mt-5">
              <h1 className="privacy-title">Privacy Policy</h1>
              <p className="mt-4">
                Last updated: August 2, 2024
              </p>
            </header>

            <section className="privacy-content mt-5">
              <h2>1. Introduction</h2>
              <p>
                Welcome to SportAI. SportAI ("we", "our", "us") is committed to
                protecting and respecting your privacy. This Privacy Policy
                explains how we collect, use, disclose, and safeguard your
                information when you visit our website sportai.no, including any
                other media form, media channel, mobile website, or mobile
                application related or connected thereto (collectively, the
                "Site"). Please read this privacy policy carefully. If you do
                not agree with the terms of this privacy policy, please do not
                access the site.
              </p>

              <h2>2. Information We Collect</h2>
              <h3>2.1. Personal Data</h3>
              <p>
                We may collect personally identifiable information (such as your
                name, email address, and telephone number) that you voluntarily
                provide to us when you register with the Site or when you choose
                to participate in various activities related to the Site, such
                as online chat and message boards. If you choose to share data
                about yourself via your profile, online chat, or other
                interactive areas of the Site, please be advised that all data
                you disclose in these areas is public and your data will be
                accessible to anyone who accesses the Site.
              </p>

              <h3>2.2. Derivative Data</h3>
              <p>
                Information our servers automatically collect when you access
                the Site, such as your IP address, your browser type, your
                operating system, your access times, and the pages you have
                viewed directly before and after accessing the Site.
              </p>

              <h3>2.3. Financial Data</h3>
              <p>
                Financial information, such as data related to your payment
                method (e.g., valid credit card number, card brand, expiration
                date) that we may collect when you purchase, order, return,
                exchange, or request information about our services from the
                Site. We store only very limited, if any, financial information
                that we collect. Otherwise, all financial information is stored
                by our payment processor, and you are encouraged to review their
                privacy policy and contact them directly for responses to your
                questions.
              </p>

              <h2>3. Use of Your Information</h2>
              <p>
                Having accurate information about you permits us to provide you
                with a smooth, efficient, and customized experience. Specifically,
                we may use information collected about you via the Site to:
              </p>
              <ul>
                <li>Create and manage your account.</li>
                <li>Process your transactions and send you related information, including purchase confirmations and invoices.</li>
                <li>Provide, operate, maintain, improve, and promote our services.</li>
                <li>Understand and analyze how you use our Site and what products and services are most relevant to you.</li>
                <li>Communicate with you, either directly or through one of our partners, including for customer service, to provide you with updates and other information relating to the Site, and for marketing and promotional purposes.</li>
                <li>Develop new products, services, features, and functionality.</li>
                <li>Send you technical notices, updates, security alerts, and support and administrative messages.</li>
                <li>Find and prevent fraud and respond to trust and safety issues that may arise.</li>
              </ul>

              <h2>4. Disclosure of Your Information</h2>
              <p>
                We may share information we have collected about you in certain
                situations. Your information may be disclosed as follows:
              </p>
              <ul>
                <li>
                  <strong>By Law or to Protect Rights:</strong> If we believe the
                  release of information about you is necessary to respond to
                  legal process, to investigate or remedy potential violations
                  of our policies, or to protect the rights, property, and
                  safety of others, we may share your information as permitted
                  or required by any applicable law, rule, or regulation. This
                  includes exchanging information with other entities for fraud
                  protection and credit risk reduction.
                </li>
                <li>
                  <strong>Business Transfers:</strong> We may share or transfer
                  your information in connection with, or during negotiations of,
                  any merger, sale of company assets, financing, or acquisition
                  of all or a portion of our business to another company.
                </li>
                <li>
                  <strong>Third-Party Service Providers:</strong> We may share your
                  information with third parties that perform services for us or
                  on our behalf, including payment processing, data analysis,
                  email delivery, hosting services, customer service, and
                  marketing assistance.
                </li>
                <li>
                  <strong>Marketing Communications:</strong> With your consent, or
                  with an opportunity for you to withdraw consent, we may share
                  your information with third parties for marketing purposes, as
                  permitted by law.
                </li>
                <li>
                  <strong>Business Partners:</strong> We may share your information
                  with our business partners to offer you certain products,
                  services, or promotions.
                </li>
                <li>
                  <strong>Other Third Parties:</strong> We may share your information
                  with advertisers and investors for the purpose of conducting
                  general business analysis. We may also share your information
                  with such third parties for marketing purposes, as permitted
                  by law.
                </li>
              </ul>

              <h2>5. Security of Your Information</h2>
              <p>
                We use administrative, technical, and physical security measures
                to help protect your personal information. While we have taken
                reasonable steps to secure the personal information you provide
                to us, please be aware that despite our efforts, no security
                measures are perfect or impenetrable, and no method of data
                transmission can be guaranteed against any interception or other
                type of misuse.
              </p>

              <h2>6. Policy for Children</h2>
              <p>
                We do not knowingly solicit information from or market to
                children under the age of 13. If we learn that we have collected
                personal information from a child under age 13 without
                verification of parental consent, we will delete that
                information as quickly as possible. If you believe we might have
                any information from or about a child under 13, please contact
                us at privacy@sportai.no.
              </p>

              <h2>7. Your Privacy Rights</h2>
              <p>
                If you are a resident in the EEA or UK, you have certain rights
                and protections under the law regarding the processing of your
                personal data. These rights include:
              </p>
              <ul>
                <li>
                  <strong>The right to access:</strong> You have the right to request
                  copies of your personal data.
                </li>
                <li>
                  <strong>The right to rectification:</strong> You have the right to
                  request that we correct any information you believe is
                  inaccurate or complete information you believe is incomplete.
                </li>
                <li>
                  <strong>The right to erasure:</strong> You have the right to
                  request that we erase your personal data, under certain
                  conditions.
                </li>
                <li>
                  <strong>The right to restrict processing:</strong> You have the
                  right to request that we restrict the processing of your
                  personal data, under certain conditions.
                </li>
                <li>
                  <strong>The right to object to processing:</strong> You have the
                  right to object to our processing of your personal data, under
                  certain conditions.
                </li>
                <li>
                  <strong>The right to data portability:</strong> You have the right
                  to request that we transfer the data that we have collected to
                  another organization, or directly to you, under certain
                  conditions.
                </li>
              </ul>

              <h2>8. Contact Us</h2>
              <p>
                If you have questions or comments about this Privacy Policy,
                please contact us at:
              </p>
              <p>
                SportAI
                <br />
                Email: privacy@sportai.com
                <br />
                Phone: +47 464 23 779
                <br />
                Address: Tordenskiolds gate 2, 4th Floor, 0160 Oslo, Norway.
              </p>
            </section>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default PrivacyPolicy;
