import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const FemaleLeaders = () => {
    return (
        <section id="news-article" className="section bg-lig text-black">
            <Container>
                <Row className="justify-content-md-center">
                    <Col md={12} className="text-center">
                        <img
                            src="../../images/empowering-the-game.jpg"
                            alt="blog-image"
                            style={{ maxHeight: "50vh" }}
                            className="img-fluid"
                        />
                        <p className="small text-muted">
                            Photo: Lauren Pedersen.
                        </p>
                    </Col>
                </Row>
                <Row className="justify-content-md-center">
                    <Col lg={6} md={8}>
                        <header className="article-header mt-5">
                            <h1 className="article-title">
                            Female Leaders Innovating in Sports Tech                            </h1>
                            <div className="article-meta mt-3">
                                <time className="small text-muted" dateTime="2024-01-XX">
                                    March 8, 2024
                                </time>
                            </div>
                        </header>
                        <section className="article-content mt-3">
                            <h4 className="article-subtitle" style={{ lineHeight: 1.5 }}>
                            In honor of International Women's Day, let's dive into the story of Lauren Pedersen, a true pioneer in the world of sports technology. With her roots in competitive tennis, Lauren has taken her love for sports and combined it with technology to create something amazing—SportAI. This article will take you through her inspiring journey from being an athlete to leading a tech company, showing how she's making sports better with smart technology. Lauren's story is a call to action for young women everywhere, encouraging them to step up, lead, and innovate in any area they're passionate about.
                            </h4>
                            <h5 className="mt-5 fw-600"></h5>
                            <p className="fw-600">
                            Q: Woman CEO in sports and tech, what do those words mean to you?
                                </p>
                            <p>
                            LP: Firstly, that collection of words is not spoken in the same sentence enough! I love to meet other female sportstech CEOs, so please introduce me when you come across them.
                            </p>
                            <p>
                            Being the CEO of a tech company in sports is my dream job. I grew up in sports and being competitive has been a part of who I am on the court and off the court. When the prospect of becoming a professional athlete closed, I took that same drive, dedication, and competitive spirit to the business world. I’m far from alone in this regard. I believe that a lot of studies have shown a strong link between high-performing female athletes and high performing business women. Let’s hope that this link brings even more female leaders into the sports and tech industries in the years to come.
                            </p>
                            <p className="fw-600">
                            Q: Can you share a bit about your journey from collegiate tennis player to CEO of SportAI?
                                </p>
                            <p>
                            LP: Certainly! My journey has been a fusion of my two greatest passions: sports and technology. Sports have been a significant part of my life since childhood, and competing at the college level and representing Norway in the master's tournaments provided me with invaluable experiences and insights into the nuances of the game. Transitioning into the world of technology, I saw an opportunity to combine my expertise with my entrepreneurial spirit to create solutions that enhance the athlete's performance and the industry as a whole. This led me to SportAI, where we're dedicated to improving the sports experience using AI to power technique analysis.
                            </p>
                            <p className="fw-600">
                            Q: What would be your advice for female student-athletes who may see you as an example of their career aspirations?
                            </p>
                            <p>
                            LP: There are the usual platitudes like practising a balanced life and keeping your commitments. These things do work for me. SportAI just launched so it is in full-time start-up mode. I have two children that are growing up. I play tennis competitively. All of these require focus, commitment, and time. Any one of these can overtake the others if I let them. Keeping commitments is a practice that, for me, makes it all work, and the people around me, in my orbit understand this. If I have a commitment to play tennis, then I am present and focused on the court. Same thing with my children. I am present and focused on them.
                            </p>
                            <p className="fw-600">
                            Q: How has your background in tennis influenced your approach to leading SportAI?
                            </p>
                            <p>
                            LP: My background in tennis has been instrumental in shaping my approach to leadership and innovation. Having been on the court, I understand firsthand the challenges athletes face and the areas where technology can make a meaningful impact. We know that everyone from the weekend warrior to the next generation of tour players depend on a coach for technique analysis. Our experience with coaches, commentators and sports brands has informed our purpose to develop a tool that will help deliver quality technique analysis to more people on a consistent basis. We aim to develop solutions that are not only technologically advanced but also deeply rooted in the needs of athletes, coaches and the industry as a whole.
                            </p>
                            <p className="fw-600">
                            Q: What do you see as the biggest opportunities for innovation in sports technology in the coming years?
                            </p>
                            <p>
                                LP: The opportunities for innovation in sports technology are vast and ever-expanding. One area with immense potential is the integration of AI and machine learning to analyze vast amounts of data and provide actionable insights for athletes and coaches. Additionally, advancements in wearable technology, virtual reality, and augmented reality present exciting possibilities for enhancing training methods and fan engagement. As the boundaries between the physical and digital worlds continue to blur, there's tremendous potential for technology to revolutionize the way we experience and interact with sports.
                            </p>
                            
                            <p className="fw-600">
                            Q: What advice would you give to young women aspiring to leadership roles in sports technology or related fields?
  
                            </p>
                            <p>
                                LP: My advice would be to embrace your passions, chase your goals with unwavering determination, and never be afraid to challenge the status quo. The sports technology industry needs diverse voices and perspectives to drive innovation forward. Build a network of mentors and allies, and don't be afraid to speak up when an opportunity arises. Trust in your own abilities and keep playing sports!
</p>
                        </section>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default FemaleLeaders;