import React from "react";
import { Link } from "react-scroll";
import { scrollDuration } from "../../../config/commonConfig";

const BottomHeaderDefaultIntro = () => {
  return (
    <section
      id="home"
      className="bg-primary d-flex flex-column fullscreen py-5 position-relative"
    >
    </section>
  );
};

export default BottomHeaderDefaultIntro;
