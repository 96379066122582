import React from "react";
import { Link } from "react-router-dom";

const blogPosts = [
  {
    title: "Why world chess champion Magnus Carlsen invested in SportAI",
    date: "May 7, 2024",
    image: "images/blog-why-chess-champion-invested-in-sportai.png",
    link: "/blog/why-chess-champion-invested-in-sportai",
  },
  {
    title: "The Future of AI in Sports: Next Level Analytics",
    date: "April 4, 2024",
    image: "images/blog-next-level-analytics.jpg",
    link: "/blog/the-future-of-ai-in-sports-next-level-analytics",
  },
  {
    title: "Female Leaders Innovating in Sports Tech",
    date: "March 8, 2024",
    image: "images/empowering-the-game.jpg",
    link: "/blog/female-leaders-innovating-in-sports-tech",
  },
];

const Blog = () => {
  return (
    <section id="blog" className="section bg-dark-green text-white">
      <div className="container">
        <h2 className="text-10 fw-600 text-center mb-5 wow fadeInUp text-white">
          Blog
        </h2>
        <div className="row">
          {blogPosts.map((post, index) => (
            <div className="col-md-6 d-flex flex-column align-items-center" key={index}>
              <div className="news-content text-center sai-hover-expand" style={{margin: 16}}>
                <Link to={post.link}>
                  <div className="">
                    <img
                      src={post.image}
                      alt="blog-image"
                      className="img-fluid sai-rounded"
                    />
                  </div>
                  <h3 className="mt-3 fw-200 text-white">{post.title}</h3>
                </Link>
                <p className="mt-2 fw-300">{post.date}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Blog;
