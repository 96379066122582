
import "./App.scss";
import WOW from "wowjs";
import AboutUs from "./components/About";
import Services from "./components/Services";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import React, { useEffect, useState } from "react";
import {
  appliedConfig,
  introBackgroundConfig,
  themeConfig,
} from "./config/commonConfig";
import { Tooltip } from "./components/Tooltip";
import FullScreenVideoBgIntro from "./components/themes/fullScreen/IntroVideoBg";
import FullScreenHeader from "./components/themes/fullScreen/Header";
import FullScreenDefaultIntro from "./components/themes/fullScreen/IntroDefault";
import FullScreenImgBgIntro from "./components/themes/fullScreen/IntroImageBg";
import BottomHeader from "./components/themes/bottomHeader/Header";
import StandardMenuHeader from "./components/themes/StandardMenu/Header";
import BottomHeaderDefaultIntro from "./components/themes/bottomHeader/IntroDefault";
import BottomHeaderImgBgIntro from "./components/themes/bottomHeader/IntroImageBg";
import BottomHeaderVideoBgIntro from "./components/themes/bottomHeader/IntroVideoBg";
import StandardMenuDefaultIntro from "./components/themes/StandardMenu/IntroDefault";
import StandardMenuImgBgIntro from "./components/themes/StandardMenu/IntroImageBg";
import StandardMenuVideoBgIntro from "./components/themes/StandardMenu/IntroVideoBg";
import News from "./components/News";
import WhatWeDo from "./components/WhatWeDo";
import Team from "./components/Team";
import OurCustomers from "./components/OurCustomers";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga';
import SignUpForm from "./components/SignUpForm";
import StandardMenuHeaderLite from "./components/themes/StandardMenu/HeaderLite";
// News
import NorwegianTechLeaders from "./components/news/NorwegianTechLeaders";
import SportAIRaisesSeedRoundSkyfall from "./components/news/SportAIRaisesSeedRoundSkyfall";
import SportAIPartnersWithMatchi from "./components/news/SportAIPartnersWithMatchi";
// Blog
import Blog from "./components/Blog";
import EmpoweringTheGame from "./components/blog/EmpoweringTheGame";
import FemaleLeaders from "./components/blog/FemaleLeaders";
import NextLevelAnalytics from "./components/blog/NextLevelAnalytics";
import WhyChessChampionInvestedInSportAI from "./components/blog/WhyChessChampionInvestedInSportAI";
// Jobs
import MLEngineer from "./components/jobs/MLEngineer";
import DevOps from "./components/jobs/DevOps";
import UXDesigner from "./components/jobs/UXDesigner";
import CookieConsent from "react-cookie-consent";
import DataProcessingAgreement from './components/DPA';
import PrivacyPolicy from "./components/PrivacyPolicy";
import TermsOfUse from "./components/TermsOfUse";
import CustomModal from "./components/NewsletterModal";
import BroadcastAndCommentary from "./components/customers/BroadcastAndCommentary"
import CoachesAndTrainingFacilities from "./components/customers/CoachesAndTrainingFacilities"
import SportsEquipmentBrandsAndRetailers from "./components/customers/SportsEquipmentBrandsAndRetailers"

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, [pathname]);

  return null;
};

function App() {
  // const [cookies] = useCookies(["cookieConsent"]);

  const appliedTheme = appliedConfig.appliedTheme;
  const appliedIntro = appliedConfig.appliedIntro;

  ReactGA.initialize('G-7C5DGWNWR9');
  ReactGA.pageview(window.location.pathname + window.location.search);
  
  const handleNavClick = (section) => {
    document.getElementById(section).scrollIntoView({ behavior: "smooth" });
  };

  const [scrollTopVisible, setScrollTopVisible] = useState(false);

  useEffect(() => {
    new WOW.WOW({
      live: false,
    }).init();
  }, []);

  const checkScrollTop = () => {
    let scrollTopBtn = document.getElementById("back-to-top");

    if (scrollTopBtn) {
      if (
        document.body.scrollTop > 400 ||
        document.documentElement.scrollTop > 400
      ) {
        setScrollTopVisible(true);
      } else {
        setScrollTopVisible(false);
      }
    }
  };

  if (typeof window !== "undefined") {
    window.addEventListener("scroll", checkScrollTop);
  }

  const getHeader = () => {
    if (appliedTheme === themeConfig.BottomHeader) {
      return <BottomHeader></BottomHeader>;
    } else if (appliedTheme === themeConfig.FullScreenMenu) {
      return (
        <FullScreenHeader
          textWhite={appliedIntro === introBackgroundConfig.image}
        ></FullScreenHeader>
      );
    } else {
      return <StandardMenuHeader></StandardMenuHeader>;
    }
  };

  const getBottomHeaderIntro = () => {
    if (appliedIntro === introBackgroundConfig.default) {
      return <BottomHeaderDefaultIntro></BottomHeaderDefaultIntro>;
    } else if (appliedIntro === introBackgroundConfig.image) {
      return <BottomHeaderImgBgIntro></BottomHeaderImgBgIntro>;
    } else {
      return <BottomHeaderVideoBgIntro></BottomHeaderVideoBgIntro>;
    }
  };

  const getFullScreenIntro = () => {
    if (appliedIntro === introBackgroundConfig.default) {
      return <FullScreenDefaultIntro></FullScreenDefaultIntro>;
    } else if (appliedIntro === introBackgroundConfig.image) {
      return <FullScreenImgBgIntro></FullScreenImgBgIntro>;
    } else {
      return <FullScreenVideoBgIntro></FullScreenVideoBgIntro>;
    }
  };

  const getStandardMenuIntro = () => {
    if (appliedIntro === introBackgroundConfig.default) {
      return <StandardMenuDefaultIntro></StandardMenuDefaultIntro>;
    } else if (appliedIntro === introBackgroundConfig.image) {
      return <StandardMenuImgBgIntro></StandardMenuImgBgIntro>;
    } else {
      return <StandardMenuVideoBgIntro></StandardMenuVideoBgIntro>;
    }
  };

  const [showModal, setShowModal] = useState(false);

  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  useEffect(() => {
    // Check if the modal has been shown before
    const hasShownModal = localStorage.getItem('hasShownModal');

    if (!hasShownModal) {
      const timer = setTimeout(() => {
        setShowModal(true);
        localStorage.setItem('hasShownModal', 'true');
      }, 20 * 1000);

      // Cleanup the timer if the component unmounts
      return () => clearTimeout(timer);
    }
  }, []);

  return (
    <Router>
      <ScrollToTop />
      <div style={{ position: "relative" }}>
        <div id="main-wrapper">
          {appliedTheme === themeConfig.BottomHeader && getBottomHeaderIntro()}
          <Routes>
            {/* Define your home page route */}
            <Route
              path="/"
              element={
                <div id="content" role="main">
                  
                  {getHeader()}
                  {appliedTheme === themeConfig.FullScreenMenu &&
                    getFullScreenIntro()}
                  {appliedTheme === themeConfig.StandardMenu &&
                    getStandardMenuIntro()}
                  <AboutUs />
                  <WhatWeDo />
                  <OurCustomers />
                  <Services />
                  <Team />
                  <News />
                  <Blog />
                  <SignUpForm/>
                  <Contact />
                <CustomModal show={showModal} handleClose={handleClose} />

                </div>
              }
              />
            {/* Define specific routes */}
            <Route
              path="/customers/coaches-and-training-facilities"
              element={
                <div>
                  <StandardMenuHeaderLite/>
                  <CoachesAndTrainingFacilities/>
                  <Contact/>
                </div>
            }/>
            <Route
              path="/customers/broadcast-and-commentary"
              element={
                <div>
                  <StandardMenuHeaderLite/>
                  <BroadcastAndCommentary/>
                  <Contact/>
                </div>
            }/>
            <Route
              path="/customers/sports-equipment-brands-and-retailers"
              element={
                <div>
                  <StandardMenuHeaderLite/>
                  <SportsEquipmentBrandsAndRetailers/>
                  <Contact/>
                </div>
            }/>
            <Route
              path="/terms-of-use"
              element={
                <div>
                  <StandardMenuHeaderLite/>
                  <TermsOfUse/>
                  <SignUpForm/>
                  <Contact/>
                </div>
            }/>
            <Route
              path="/privacy-policy"
              element={
                <div>
                  <StandardMenuHeaderLite/>
                  <PrivacyPolicy/>
                  <SignUpForm/>
                  <Contact/>
                </div>
            }/>
            <Route
              path="/data-process-agreement"
              element={
                <div>
                  <StandardMenuHeaderLite/>
                  <DataProcessingAgreement/>
                  <SignUpForm/>
                  <Contact/>
                </div>
            }
            />
            <Route
              path="/news/norwegian-tech-leaders-unite"
              element={
                <div>
                  <StandardMenuHeaderLite/>
                  <NorwegianTechLeaders/>
                  <SignUpForm/>
                  <Contact/>
                </div>
            }
            />
            <Route
              path="/news/sport-ai-raises-capital"
              element={
                <div>
                  <StandardMenuHeaderLite/>
                  <SportAIRaisesSeedRoundSkyfall/>
                  <SignUpForm/>
                  <Contact/>
                </div>
            }
            />
            <Route
              path="/news/sport-ai-partners-with-matchi"
              element={
                <div>
                  <StandardMenuHeaderLite/>
                  <SportAIPartnersWithMatchi/>
                  <SignUpForm/>
                  <Contact/>
                </div>
            }
            />
            <Route
              path="/blog/empowering-the-game"
              element={
                <div>
                  <StandardMenuHeaderLite/>
                  <EmpoweringTheGame/>
                  <SignUpForm/>
                  <Contact/>
                </div>
            }
            />
            <Route
              path="/blog/female-leaders-innovating-in-sports-tech"
              element={
                <div>
                  <StandardMenuHeaderLite/>
                  <FemaleLeaders/>
                  <SignUpForm/>
                  <Contact/>
                </div>
            }
            />
            <Route
              path="/blog/the-future-of-ai-in-sports-next-level-analytics"
              element={
                <div>
                  <StandardMenuHeaderLite/>
                  <NextLevelAnalytics/>
                  <SignUpForm/>
                  <Contact/>
                </div>
            }
            />
            <Route
              path="/blog/why-chess-champion-invested-in-sportai"
              element={
                <div>
                  <StandardMenuHeaderLite/>
                  <WhyChessChampionInvestedInSportAI/>
                  <SignUpForm/>
                  <Contact/>
                </div>
            }
            />
            {/* <Route
              path="/jobs/ml-engineer"
              element={
                <div>
                  <StandardMenuHeaderLite/>
                  <MLEngineer/>
                  <SignUpForm/>
                  <Contact/>
                </div>
            }
            /> */}
            <Route
              path="/jobs/dev-ops"
              element={
                <div>
                  <StandardMenuHeaderLite/>
                  <DevOps/>
                  <SignUpForm/>
                  <Contact/>
                </div>
            }
            />
            <Route
              path="/jobs/ux-designer"
              element={
                <div>
                  <StandardMenuHeaderLite/>
                  <UXDesigner/>
                  <SignUpForm/>
                  <Contact/>
                </div>
            }
            />
            {/* You can add more routes here as needed */}
          </Routes>
          {/* <CookieConsent
          enableDeclineButton
          overlay
          acceptOnOverlayClick
          buttonText="Accept"
          declineButtonText="Accept 1st party cookies"
          declineButtonStyle={{ background: "darkGrey", color: "grey" }}
          buttonStyle={{ background: "#2e7d32", color: "white" }}
          >
          This page uses cookies to enhance your user experience. By using our website, you agree to our use of cookies.{" "}<br/>
          By clicking "Accept", you agree to the storing of cookies on your device.{" "}<br/>
          <a href={"/privacy-policy"}>Learn more.</a>
        </CookieConsent> */}

          <Footer handleNavClick={handleNavClick} />
        </div>
        {/* back to top */}

        
        <Tooltip text="Back to Top" placement="left">
          <span
            id="back-to-top"
            className="rounded-circle"
            style={{ display: scrollTopVisible ? "inline" : "none" }}
            onClick={() => {
              window.scrollTo({ top: 0, behavior: "smooth" });
            }}>
            <i className="fas fa-arrow-up"></i>
          </span>
        </Tooltip>
      </div>
    </Router>
  );
}

export default App;
