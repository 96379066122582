import React, { useState } from "react";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import { track } from '@vercel/analytics';

const url = "//app.us21.list-manage.com/subscribe/post?u=50fc1a377969ad935d8055fa9&amp;id=4132fdaf6b&amp;f_id=00b9f0e6f0";

const SignUpForm = () => {
  // Custom form component
  const CustomForm = ({ onSubmitted }) => {
    const [email, setEmail] = useState(""); // State to store email input

    // Function to update state when email input changes
    const handleEmailChange = (event) => {
      setEmail(event.target.value);
    };

    // Form submission logic
    const handleSubmit = (e) => {
      e.preventDefault();
      onSubmitted({ EMAIL: email });
    };

    return (    
      <form style={{ display: 'flex', alignItems: 'center' }} onSubmit={handleSubmit} className="form-border">
        <input
          type="email"
          value={email}
          onChange={handleEmailChange}
          placeholder="Enter your email"
          required
          className="form-control py-1"
        />
        <button 
            type="submit"
            style={{ marginLeft: '30px' }}
            className="btn btn-primary rounded-0">
                Subscribe</button>
      </form>
    );
  };

  return (
    <section id="contact" className="section bg-light">
        <div className="container">
            <div className="row">
                <div className="col-lg-5 text-center text-lg-start wow fadeInUp">
                    <p className="text-5 margin-right-4">Subscribe to our newsletter and get the latest updates</p>
                    <MailchimpSubscribe
                      url={url}
                      render={({ subscribe, status, message }) => (
                        <div>
                            <CustomForm onSubmitted={(formData) => subscribe(formData)} />
                            {status === "sending" && <div style={{ color: "blue" }}>sending...</div>}
                            {status === "error" && <div style={{ color: "red" }} dangerouslySetInnerHTML={{ __html: message }} />}
                            {status === "success" && <div style={{ color: "green" }}>Subscribed ! {track('newsletter-subscribed')}</div>}
                        </div>)}/>
                </div>
            </div>
        </div>
    </section>
  );
};

export default SignUpForm;
