import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import SignUpForm from "../SignUpForm";
import Contact from "../Contact";

const RaisesSeedRound = () => {
  return (
    <section id="news-article" className="section bg-lig text-black">
      <Container>
        <Row className="justify-content-md-center">
          <Col md={12} className="text-center">
            <img
              src="../../images/SportAI_Co_Founders.jpg"
              alt="news-image"
              style={{ maxHeight: "50vh" }}
              className="img-fluid"
            />
            <p className="small text-muted">
              Photo: Felipe Longé, Trond Kittelsen, Lauren Pedersen.
            </p>
          </Col>
        </Row>
        <Row className="justify-content-md-center">
          <Col lg={6} md={8}>
            <header className="article-header mt-5">
              <h1 className="article-title">
              SportAI Raises $1.8M Seed Round Led by Skyfall Ventures
              </h1>
              <div className="article-meta mt-3">
                <time className="small text-muted" dateTime="2024-01-XX">
                  August 19, 2024
                </time>
              </div>
            </header>
            <section className="article-content mt-3">
              <h4 className="article-subtitle" style={{ lineHeight: 1.5 }}>
              Harnessing machine learning and computer vision to capture and interpret biomechanical data, SportAI is using AI-powered technology to redefine how we play, coach, watch and analyze sports
              </h4>
              <h5 className="mt-5 fw-600">Oslo, Norway, 19th AUGUST 2024:</h5>
              <p>
              SportAI, the B2B sports technology company using AI to enhance sports technique coaching, commentary and analysis, has raised a $1.8M seed funding round.
              </p>
            </section>
            <p>
            The round was led by early-stage investor, Skyfall Ventures. Other participants include Norwegian pension fund MP Pensjon; Dekel Valtzer (ex-pro tennis player and AI startup founder); and Sverre Munck (ex-EVP at Schibsted). Magnus Carlsen, the highest ranked chess player of all time, is a SportAI advisor and investor in their pre-seed round. 
            </p>
            <p>
            SportAI analyzes players’ technique in real-time, such as their tennis or padel swing, before delivering instant, personalized feedback for improving performance. 
            <video width="600" controls>
        <source src="../../images/Tennis-Swing-Analysis-Demo-v5.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
            {/* <iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/VslvnODshSs?si=2zXPbrWxFN4uNcxB" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe> */}
            <br/>
            Using machine learning, computer vision and biometric technology, an individual’s technique can be instantly compared to thousands of other players, including top sporting professionals, with objective and precise written and visual analysis. As a platform, SportAI can offer automated data-based analysis to millions of players, a service typically only affordable for professional players with access to performance analysts. Broadcasters can also use the technology to enrich their live sporting commentary, enhancing fan experience with instant technique analysis, while equipment brands can use it for product recommendations based on a player’s personal technique.
            </p>
            <p>
            SportAI is hardware agnostic, capable of analyzing video from mobile phones, cameras mounted at courts or playing arenas, and broadcast feeds. The technology makes technique analysis scalable, objective, and affordable for the first time. Previously, video analysis required manual tagging by coaches using specific hardware, which limited scalability and longevity. By partnering with major sports organizations and automating analysis from any video, SportAI can reach wide audiences and integrate technique analysis into training for pros, recreational players and fans. This democratizes access to high-quality sporting analysis for players of all levels worldwide.
            </p>
            <br/>
            <img
              src="../../images/office_sign.jpg"
              alt="news-image"
              style={{ maxHeight: "50vh" }}
              className="img-fluid"
            />
            <br/>
            <br/>
            <p>
            The Oslo-headquartered company was co-founded in late 2023 by ex-NCAA tennis player Lauren Pedersen (CEO), Felipe Longé (CTO) and Andreas Thome (Board Chairman, ex-CEO of Play Magnus) alongside tech-startup heavyweights Espen Agdestein (Board member), and Trond Kittelsen (Head of Commercial and tennis app founder). SportAI is tapping into the burgeoning AI in sports market, which is set to reach $29.7B by 2032. SportAI’s cloud-based SaaS can be integrated by businesses to enhance their broadcast coverage, sports facilities, coaching programmes, training apps, and equipment brands and retailers.
            </p>
            <p>
            This seed funding round will be used to scale SportAI's technology development, expand the team and increase market penetration. The company's innovative approach has already attracted attention from across the sports industry, leading to its first, soon-to-be-announced commercial contract signed in June 2024.
            </p>
            <section className="article-content mt-3">
            <p>
            Lauren Pedersen, CEO and co-founder of SportAI, comments: <b>"We are thrilled to have Skyfall Ventures lead our seed round, which underscores the growing confidence in SportAI's potential to disrupt the sports industry with our state-of-the-art technology. This funding will enable us to accelerate the development of our AI-driven solutions and expand our reach within the sports industry. We are committed to harnessing the power of AI to democratize access to high-quality sports data and achieve sports excellence."</b>
            </p>
            <p>
            Jon Kåre Stene, Partner and Co-Founder of Skyfall Ventures, adds: <b>"Skyfall Ventures is proud to support SportAI in their mission to make world-class expertise accessible to all. Like OpenAI’s ambition to make intelligence abundant and accessible, SportAI is working to ensure that everybody has access to the best sports training in the world. SportAI's innovative approach and cutting-edge solutions align perfectly with our focus on investing in high-growth technology startups. We are excited to contribute to their journey and help them achieve substantial market success.”</b>
            </p>
            </section>








              <br />
            <p>
              For more information please contact:
              <br />
              Lauren Pedersen
              <br />
              Chief Executive Officer | SportAI
              <br />
              <a className="text-black" href="lauren.pedersen@sportai.no">
                lauren.pedersen@sportai.com
              </a>
              <br />
              <a className="text-black" href="tel:004790663094">
                +47 906 63 094
              </a>
              <br />
              or{" "}
              <a className="text-black" href="contact@sportai.no">
                contact us
              </a>
            </p>
            <p className="small text-muted">
              1. Source: https://thenextweb.com/news/sportai-analytics-for-tennis-golf
            </p>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default RaisesSeedRound;
