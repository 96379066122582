import React from "react";

const FullScreenVideoBgIntro = () => {
  return (
    <section id="home">
    </section>
  );
};

export default FullScreenVideoBgIntro;
