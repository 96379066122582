import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const UXDesigner = () => {
    return (
        <section id="news-article" className="section bg-lig text-black">
            <Container>
                <Row className="justify-content-md-center">
                    <Col md={12} className="text-center">
                        <img
                            src="../../images/intro-bg.jpg"
                            alt="job-ad-image"
                            style={{ maxHeight: "50vh" }}
                            className="img-fluid"
                        />
                    </Col>
                </Row>
                <Row className="justify-content-md-center">
                    <Col lg={6} md={8}>
                        <header className="article-header mt-5">
                            <h1 className="article-title">
                                UX / UI Designer
                            </h1>
                            <div className="article-meta mt-3">
                                <time className="small text-muted" dateTime="2024-01-XX">
                                    Location: Oslo, Norway
                                </time>
                            </div>
                        </header>
                        <section className="article-content mt-3">
                            <h4 className="article-subtitle" style={{ lineHeight: 1.5 }}>
                                <b>SportAI</b> is seeking a talented <b>Design Lead UX/UI</b> to join our dynamic team in Oslo. The ideal candidate will have a robust background in designing exceptional UX experiences for web and mobile apps, complemented by strong UI graphic design skills. As UX/UI Designer at SportAI, you will set design guidelines and drive the branding efforts for our company. This role provides a unique opportunity to work with cutting-edge technology and make a significant impact on the sports industry.
                            </h4>
                            <h5 className="mt-5 fw-600">About Us:</h5>
                            <p>
                            SportAI is an innovative sports technology company specializing in SaaS solutions for the sports industry. We are pioneers in advanced computer vision and machine learning, delivering instant and precise sports technique analysis. SportAI AI-powered sports technique analysis is aimed to be accessible to everyone, ensuring accurate and instant feedback and promoting better performance in sports.  We are ready to expand our team with a new colleague passionate about sports, innovation, and technology. If you are a self-starter who thrives in a high-energy work environment, we would love to hear from you!
                            </p>
                            <h5 className="mt-5 fw-600">Responsibilities:</h5>
                            <ul>
                                <li>Lead the design of new AI features and capabilities through collaboration with product managers, engineers, customers, marketing, and domain experts.</li>
                                <li>Product design for new and existing products.</li>
                                <li>Develop UX design solutions for complex computer vision capabilities.</li>
                                <li>Uphold and evolve SportAI’s design guidelines and branding.</li>
                            </ul>
                            <h5 className="mt-5 fw-600">Requirements:</h5>
                            <ul>
                                <li>Proven ability to multitask and take responsibility for overall design.</li>
                                <li>A degree in Graphic Design or a related field (relevant experience can compensate).</li>
                                <li>3+ years of professional experience in UI and UX design.</li>
                                <li>Effective communication skills with commercial and developers of all disciplines, technical skills, and most importantly, our customers.</li>
                                <li>Highly collaborative with a startup mindset, able to work pragmatically and at a fast pace.</li>
                                <li>Experience with successful products, both SaaS platforms and consumer products (web and app).</li>
                                <li>Passion for sports, or the ability to quickly build domain knowledge about different sports.</li>
                                <li>Familiarity with working alongside web developers using the latest standards.</li>
                                <li>Strong English language skills.</li>
                                <li>Located in Oslo or within daily commuting distance.</li>
                            </ul>
                            <h5 className="mt-5 fw-600">Bonus skills:</h5>
                            <ul>
                                <li>Knowledge of SEO.</li>
                                <li>Strong branding capabilities.</li>
                                <li>Experience creating social media content.</li>
                                <li>Video editing skills.</li>
                                <li>Familiar with racket sports.</li>
                            </ul>
                            <h5 className="mt-5 fw-600">Benefits:</h5>
                            <ul>
                                <li>Competitive salary.</li>
                                <li>An opportunity to own shares in the company.</li>
                                <li>Flexible working schedules to balance professional and personal life.</li>
                                <li>A commitment to continuous professional growth and skill development.</li>
                                <li>A vibrant and positive team of colleagues and regular team building events.</li>
                                <li>A centrally located, modern, and well-equipped office space in Aker Brygge, Oslo (Norway).</li>
                            </ul>
                            <h5 className="mt-5 fw-600">How to Apply:</h5>
                            <p>
                                If you are interested in becoming a vital member of our team, please submit your CV, portfolio, and a cover letter to: <a href="mailto:jobs@sportai.com?subject=MLEngineer%20job">jobs@sportai.com</a>. We look forward to hearing from you and welcoming you to SportAI!
                            </p>
                        </section>

                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default UXDesigner;