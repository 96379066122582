import React, { useState } from "react";
import { Link } from "react-scroll";
import { scrollDuration } from "../../../config/commonConfig";
import { Tooltip } from "../../Tooltip";
import ReactGA from 'react-ga';

const StandardMenuHeader = () => {
  const [isNavModalClose, setIsNavModalClose] = useState(true);

  return (
    <header id="header" className="sticky-top">
      {/* Navbar */}
      <nav className="primary-menu navbar navbar-expand-lg text-uppercase navbar-line-under-text fw-600">
        <div className="container position-relative">
          <div className="col-auto d-inline-flex ps-lg-0">
            {/* Logo */}
            <Link
              className="logo"
              title="SportAI"
              smooth="easeInOutQuint"
              duration={scrollDuration}
              style={{ cursor: "pointer" }}
              offset={-72}
              to="home"
              onClick={(e) => {
                e.preventDefault();
                setIsNavModalClose(true);
              }}
            >
              <img height={63} src="images/logo-dark-green.png" alt="Sport AI" />
            </Link>
            {/* Logo End */}
          </div>
          <div className="col col-lg-8 navbar-accordion px-0">
            <button
              className={
                "navbar-toggler ms-auto collapsed " +
                (isNavModalClose ? "" : "show")
              }
              type="button"
              onClick={() => {
                ReactGA.event({
                  category: 'User',
                  action: 'Clicked the Header Logo'
                });
                setIsNavModalClose(!isNavModalClose)
              }}
            >
              <span />
              <span />
              <span />
            </button>
            <div
              id="header-nav"
              className={
                "collapse navbar-collapse justify-content-center " +
                (isNavModalClose ? "" : "show")
              }
            >
              <ul className="navbar-nav">
                <li className="nav-item">
                  {/* <Link
                    className="nav-link "
                    smooth="easeInOutQuint"
                    duration={scrollDuration}
                    style={{ cursor: "pointer" }}
                    activeClass="active"
                    spy
                    to="home"
                    offset={-71}
                    onClick={(e) => {
                      e.preventDefault();
                      setIsNavModalClose(true);
                    }}
                  >
                    Home
                  </Link> */}
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link "
                    smooth="easeInOutQuint"
                    duration={scrollDuration}
                    style={{ cursor: "pointer" }}
                    activeClass="active"
                    spy
                    to="about"
                    onClick={(e) => {
                      ReactGA.event({
                        category: 'User',
                        action: 'Clicked the WhatWeDo About Button'
                      });
                      e.preventDefault();
                      setIsNavModalClose(true);
                    }}
                  >
                    About
                  </Link>
                </li>
                <li className="nav-item">
                  {/* <Link
                    className="nav-link"
                    smooth="easeInOutQuint"
                    duration={scrollDuration}
                    style={{ cursor: "pointer" }}
                    activeClass="active"
                    spy
                    to="what-we-do"
                    onClick={(e) => {
                      ReactGA.event({
                        category: 'User',
                        action: 'Clicked the WhatWeDo Header Button'
                      });
                      e.preventDefault();
                      setIsNavModalClose(true);
                    }}
                  >
                    What We Do
                  </Link> */}
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link "
                    smooth="easeInOutQuint"
                    duration={scrollDuration}
                    style={{ cursor: "pointer" }}
                    activeClass="active"
                    spy
                    to="our-customers"
                    onClick={(e) => {
                      ReactGA.event({
                        category: 'User',
                        action: 'Clicked the WhatWeDo About Button'
                      });
                      e.preventDefault();
                      setIsNavModalClose(true);
                    }}
                  >
                    Customers
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link "
                    smooth="easeInOutQuint"
                    duration={scrollDuration}
                    style={{ cursor: "pointer" }}
                    activeClass="active"
                    spy
                    to="services"
                    onClick={(e) => {
                      ReactGA.event({
                        category: 'User',
                        action: 'Clicked the OurCustomers Header Button'
                      });
                      e.preventDefault();
                      setIsNavModalClose(true);
                    }}
                  >
                    Tech
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link "
                    smooth="easeInOutQuint"
                    duration={scrollDuration}
                    style={{ cursor: "pointer" }}
                    activeClass="active"
                    spy
                    to="team"
                    onClick={(e) => {
                      ReactGA.event({
                        category: 'User',
                        action: 'Clicked the Team Header Button'
                      });
                      e.preventDefault();
                      setIsNavModalClose(true);
                    }}
                  >
                    Team
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link "
                    smooth="easeInOutQuint"
                    duration={scrollDuration}
                    style={{ cursor: "pointer" }}
                    activeClass="active"
                    spy
                    to="news"
                    onClick={(e) => {
                      ReactGA.event({
                        category: 'User',
                        action: 'Clicked the News Header Button'
                      });
                      e.preventDefault();
                      setIsNavModalClose(true);
                    }}
                  >
                    News
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link "
                    smooth="easeInOutQuint"
                    duration={scrollDuration}
                    style={{ cursor: "pointer" }}
                    activeClass="active"
                    spy
                    to="blog"
                    onClick={(e) => {
                      ReactGA.event({
                        category: 'Blog',
                        action: 'Clicked the Blog Header Button'
                      });
                      e.preventDefault();
                      setIsNavModalClose(true);
                    }}
                  >
                    Blog
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link "
                    smooth="easeInOutQuint"
                    duration={scrollDuration}
                    style={{ cursor: "pointer" }}
                    activeClass="active"
                    spy
                    to="contact"
                    onClick={(e) => {
                      ReactGA.event({
                        category: 'User',
                        action: 'Clicked the Contact Header Button'
                      });
                      e.preventDefault();
                      setIsNavModalClose(true);
                    }}
                  >
                    Contact
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-auto col-lg-2 d-flex justify-content-end ps-0">
            {/* <Link
                    className="btn btn-primary btn-sm fw-00 rounded-0"
                    smooth="easeInOutQuint"
                    duration={scrollDuration}
                    style={{ cursor: "pointer" }}
                    to="contact"
                  >
                    Request Demo
                  </Link> */}
          </div>
        </div>
      </nav>
      {/* Navbar End */}
    </header>
  );
};

export default StandardMenuHeader;
